import React from 'react';

function Skrill({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4622 9.00041C21.4622 8.44806 21.8997 8 22.4385 8C22.9782 8 23.4154 8.44806 23.4154 9.00041C23.4154 9.5531 22.9782 10.0002 22.4385 10.0002C21.8997 10.0002 21.4622 9.5531 21.4622 9.00041ZM24.2692 8.81815L26.2494 8.45522V16.2412H24.2692V8.81815ZM27.1615 8.81815V16.2412H29.1429V8.45522L27.1615 8.81815ZM21.4486 10.5464V16.2412H23.4297V10.5464H21.4486ZM17.5707 13.0375C17.5707 11.3648 18.5007 10.5233 20.331 10.4629C20.331 10.4629 20.6617 10.4499 20.8568 10.4924V12.157C19.6965 12.1979 19.5683 12.6271 19.5683 13.6282V16.2412H17.5707V13.0375ZM14.9564 10.5399L14.9463 10.5667L14.9463 10.5667C14.8754 10.7574 14.5525 11.6252 13.8281 12.5225V8.45522L11.7836 8.86948V16.2412H13.8281V13.9622C14.4202 14.872 14.7127 16.2412 14.7127 16.2412H17.1611C16.919 15.2099 15.8597 13.3088 15.8597 13.3088C16.8109 12.0735 17.2281 10.7579 17.2947 10.5399H14.9564ZM7.5248 10.7621C7.5248 11.2966 8.10478 11.3353 8.35512 11.3518C10.1912 11.4779 11.2855 12.3984 11.2855 13.8147C11.2855 15.0643 10.307 16.3247 8.12414 16.3247C7.11202 16.3247 6.24838 16.2226 5.48247 16.0163V14.1974C5.99392 14.4129 6.83662 14.639 7.70469 14.639C8.49694 14.639 8.9681 14.3706 8.9681 13.9144C8.9681 13.3641 8.39671 13.3282 8.17759 13.3145L8.17522 13.3143C5.61826 13.1359 5.34286 11.5868 5.34286 10.9291C5.34286 9.78704 6.13416 8.45522 8.36749 8.45522C9.66294 8.45522 10.3613 8.66219 10.9628 8.87761L10.993 8.88703V10.6536L10.9705 10.6471C10.729 10.5425 10.4454 10.4398 10.4454 10.4398C9.9358 10.2835 9.22319 10.1172 8.6724 10.1172C8.35924 10.1172 7.5248 10.1172 7.5248 10.7621Z"
        fill="#862165"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Skrill;
