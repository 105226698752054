import React, { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import Icons from '../../assets/icons';
import { getInitials } from '@portal/utils/string';
import Avatar from '../../display/Avatar';
import User from '../../types/UserType';
import { classNames, isActivePath } from '../../utils/helper';

export type NavigationProps = {
  name: string;
  pathname: string;
  link: React.ReactNode;
};

export type UserNavigationProps = {
  name: string;
  href: string;
  action: (e: any) => void;
};

export type NavBarProps = {
  navigation: NavigationProps[];

  userNavigation: UserNavigationProps[];
  user: User;
  logo: string;
  extra?: React.ReactNode;
};

const NavBar = ({ navigation, user, logo, userNavigation, extra }: NavBarProps) => {
  const initials = getInitials(user.name);
  return (
    <Disclosure as="nav" className="bg-gray-900">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img className="h-8 w-8" src={logo} alt="Your Company" />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-center space-x-4">
                    {navigation.map((item) => (
                      <div
                        key={item.name}
                        className={classNames(
                          isActivePath(item.pathname) ? 'bg-gray-700 !text-white' : '!text-gray-100 hover:bg-gray-700 ',
                          'block rounded-md px-3 py-2 text-base font-medium  hover:bg-gray-700 hover:text-white'
                        )}
                      >
                        {item.link}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6 md:gap-3">
                  {extra}
                  <button
                    type="button"
                    className="rounded-full bg-gray-900 p-1 hover:bg-gray-700 focus:text-white focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <Icons.Bell className="h-6 w-6" aria-hidden="true" color="white" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <Avatar imageUrl={user.imageUrl} initials={initials} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((menuItem) => (
                          <Menu.Item key={menuItem.name}>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={menuItem.action}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {menuItem.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <Icons.Cross className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Icons.Menu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              {navigation.map((item) => (
                <div
                  key={item.name}
                  className={classNames(
                    isActivePath(item.pathname)
                      ? 'bg-gray-900 text-gray-100'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={isActivePath(item.pathname) ? 'page' : undefined}
                >
                  {item.link}
                </div>
              ))}
            </div>
            <div className="border-t border-gray-700 pt-4 pb-3">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img className="h-10 w-10 object-contain rounded-full" src={user.imageUrl} alt="" />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">{user.name}</div>
                  <div className="text-sm font-medium text-gray-400">{user.email}</div>
                </div>

                <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <Icons.Bell className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {userNavigation.map((menuItem) => (
                  <a
                    href="#"
                    key={menuItem.name}
                    onClick={menuItem.action}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    {menuItem.name}
                  </a>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
