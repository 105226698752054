import React from 'react';

function Affirm({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6344 11.9691H13.5255V11.534C13.5255 10.968 13.8489 10.806 14.1278 10.806C14.4356 10.806 14.6754 10.9426 14.6754 10.9426L15.0529 10.0798C15.0529 10.0798 14.6702 9.82965 13.9745 9.82965C13.1924 9.82965 12.3024 10.2706 12.3024 11.6544V11.9691H10.446V11.534C10.446 10.968 10.7693 10.806 11.0477 10.806C11.2062 10.806 11.4194 10.8429 11.5958 10.9426L11.9733 10.0798C11.748 9.94723 11.386 9.82965 10.895 9.82965C10.1128 9.82965 9.2229 10.2706 9.2229 11.6544V11.9691H8.51281V12.9132H9.2229V16.2487H10.446V12.9132H12.3024V16.2487H13.5255V12.9132H14.6344V11.9691ZM5.7049 15.4808C5.38329 15.4808 5.22306 15.3229 5.22306 15.0618C5.22306 14.5788 5.76369 14.4134 6.75044 14.3091C6.75044 14.9552 6.31355 15.4808 5.7049 15.4808ZM6.13026 11.8434C5.42536 11.8434 4.61383 12.1759 4.17349 12.5269L4.57579 13.3736C4.92911 13.0503 5.50029 12.7742 6.01557 12.7742C6.50491 12.7742 6.77522 12.9379 6.77522 13.267C6.77522 13.4895 6.59597 13.6013 6.25707 13.6451C4.99251 13.8099 4 14.1586 4 15.1339C4 15.9068 4.55043 16.3748 5.41038 16.3748C6.02364 16.3748 6.57004 16.0336 6.82998 15.5834V16.2486H7.9735V13.4618C7.9735 12.3108 7.1735 11.8434 6.13026 11.8434ZM17.6139 11.9691V16.2487H18.8381V14.187C18.8381 13.2066 19.4312 12.919 19.8451 12.919C20.0064 12.919 20.2243 12.9657 20.3684 13.0735L20.5915 11.942C20.4024 11.8614 20.2047 11.8435 20.0427 11.8435C19.4134 11.8435 19.018 12.1225 18.7569 12.6884V11.9691H17.6139ZM24.8832 12.5952C25.1351 12.2263 25.6187 11.8436 26.2659 11.8436C27.0481 11.8436 27.6953 12.3289 27.6953 13.2908V16.2488H26.4723V13.6776C26.4723 13.111 26.1311 12.8759 25.8077 12.8759C25.4031 12.8759 24.9985 13.2459 24.9985 14.0459V16.2488H23.7754V13.6862C23.7754 13.1024 23.4538 12.8759 23.104 12.8759C22.7172 12.8759 22.3034 13.2545 22.3034 14.0459V16.2488H21.0786V11.9692H22.2584V12.6165C22.4654 12.2032 22.9132 11.8436 23.5605 11.8436C24.1535 11.8436 24.6492 12.1185 24.8832 12.5952ZM16.5866 16.2489H15.3647V11.9693H16.5866V16.2489Z"
        fill="#060809"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.4933 6C19.1896 6 16.2449 8.29338 15.4092 11.2421H16.6069C17.3049 9.04612 19.6743 7.11873 22.4933 7.11873C25.9199 7.11873 28.8813 9.72739 28.8813 13.7885C28.8813 14.7003 28.7631 15.5222 28.5395 16.2484H29.7014L29.713 16.2087C29.9032 15.4594 30 14.6456 30 13.7885C30 9.25937 26.6997 6 22.4933 6Z"
        fill="#0FA0EA"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Affirm;
