import { Title } from '@portal/ui';
import { Helmet } from 'react-helmet';

export type PageHeaderProps = {
  title?: string;
  description?: string;
  actions?: React.ReactNode[];
};

const PageHeader = ({ title, description, actions = [] }: PageHeaderProps) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex px-3.5 md:px-0 items-center">
        <div className="flex-grow">
          <Title>{title}</Title>
          {description && <p className="text-base font-normal text-gray-600 mt-1">{description}</p>}
        </div>
        <div className="flex">
          {actions.map((item, idx) => (
            <div key={idx} className="flex-none ml-2">
              {item}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PageHeader;
