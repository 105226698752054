import { Attachment } from "@portal/database/definitions/types";

const getAttachmentPublicUrl = (
  attachment?: Attachment | null
): string | null => {
  return attachment != null && attachment.uploadAcknowledged
    ? attachment.publicUrl
    : attachment?.previousPublicUrl ?? null;
};

const getLogoUrl = (img: File) => {
  return img ? URL.createObjectURL(img) : "";
};

const formatOffset = (offset: number): string => {
  const sign = offset >= 0 ? "+" : "-";
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `${sign}${padZero(hours)}:${padZero(minutes)}`;
};

export function padZero(num: number): string {
  return num.toString().padStart(2, "0");
}

const currencyFormatter = (value: number, currency = "USD") => {
  return value?.toLocaleString("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  });
};

const bytesToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

export {
  getAttachmentPublicUrl,
  getLogoUrl,
  formatOffset,
  currencyFormatter,
  bytesToSize,
};
