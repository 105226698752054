import { useState, useRef, useEffect } from 'react';
import Icons from '../../assets/icons';
import classNames from 'classnames';

export type AvatarProps = {
  id?: string;
  size?: 'small' | 'base' | 'large' | 'xl_large' | 'x_small' | 'x_large';
  imageUrl?: string | undefined | null;
  initials?: React.ReactNode;
  editable?: boolean;
  onAvatarChange?: (file: File | null) => void;
  loading?: boolean;
};

const sizeMappings: Record<string, string> = {
  x_small: 'h-6 w-6',
  small: 'h-8 w-8',
  base: 'h-10 w-10',
  large: 'h-12 w-12',
  x_large: 'h-14 w-14',
  xl_large: 'h-24 w-24',
};

const Avatar = ({
  id,
  imageUrl,
  initials,
  size = 'base',
  editable = false,
  onAvatarChange,
  loading = false,
}: AvatarProps) => {
  const generatedClass = sizeMappings[size];
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [loadingImage, setloadingImage] = useState<boolean>(false);
  useEffect(() => {
    if (loading) setloadingImage(true);
  }, [loading]);

  const inputFile = useRef<HTMLInputElement>(null);

  return (
    <>
      {editable && (
        <input
          id={id}
          type="file"
          className="hidden"
          accept="image/png, image/gif, image/jpeg"
          onChange={(event) =>
            onAvatarChange &&
            onAvatarChange(event?.target?.files && event?.target?.files.length > 0 ? event?.target?.files[0] : null)
          }
          ref={inputFile}
        />
      )}
      <div
        className={classNames('relative', { editable: 'cursor-pointer' })}
        onMouseOver={() => setShowEditIcon(true)}
        onMouseOut={() => setShowEditIcon(false)}
        onClick={() => inputFile.current?.click()}
      >
        {editable && showEditIcon && (
          <Icons.Pencil className="h-6 w-6 cursor-pointer absolute top-9 left-9" color="#7D89B0" />
        )}
        {(loading || loadingImage) && (
          <svg
            className="animate-spin h-6 w-6 absolute top-9 left-9"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}

        {imageUrl ? (
          <img
            className={`${generatedClass} rounded-full ease-in-out transition-all ${
              editable && (showEditIcon || loading || loadingImage) && 'opacity-5'
            }`}
            src={imageUrl}
            onLoad={() => setloadingImage(false)}
            alt="avatar"
            onError={() => setloadingImage(false)}
          />
        ) : (
          <span
            className={`inline-flex items-center justify-center rounded-full bg-avatarBg ${generatedClass} ${
              editable && (showEditIcon || loading) && 'opacity-5'
            }`}
          >
            <span className="text-sm initials font-medium leading-none text-white">{initials}</span>
          </span>
        )}
      </div>
    </>
  );
};

export default Avatar;
