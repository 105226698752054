type HorizontalLabelProps = {
  label: string;
  labelFor?: string;
  info?: string;
};

export const HorizontalLabel = ({ label, labelFor, info }: HorizontalLabelProps) => {
  return (
    <div className="flex justify-between flex-col">
      <label className="text-sm font-medium text-steel-700" htmlFor={labelFor}>
        {label}
      </label>
      {info && <span className="text-sm font-normal text-steel-600 leading-5">{info}</span>}
    </div>
  );
};
