import { ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

export const Location = ({
  width = ICON_WIDTH,
  height = ICON_HEIGHT,
  color = '#404968',
  className = '',
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="-2 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99984 8.66683C7.10441 8.66683 7.99984 7.7714 7.99984 6.66683C7.99984 5.56226 7.10441 4.66683 5.99984 4.66683C4.89527 4.66683 3.99984 5.56226 3.99984 6.66683C3.99984 7.7714 4.89527 8.66683 5.99984 8.66683Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99984 14.6668C8.6665 12.0002 11.3332 9.61235 11.3332 6.66683C11.3332 3.72131 8.94536 1.3335 5.99984 1.3335C3.05432 1.3335 0.666504 3.72131 0.666504 6.66683C0.666504 9.61235 3.33317 12.0002 5.99984 14.6668Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
