enum BadgeType {
  Success = 'success',
  Primary = 'primary',
  Secondary = 'secondary',
  Cancelled = 'cancelled',
  Warning = 'warning',
  Custom = 'custom',
  Draft = 'draft',
}

export default BadgeType;
