import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { Table } from '@tanstack/react-table';
import classNames from 'classnames';
import Icons from '../../assets/icons';
import Button from '../Button';

const PAGE_COUNT_THRESHOLD = 3;

const renderPagination = <T,>(table: Table<T>) => {
  const totalPages = table.getPageCount();
  const currentPage = table.getState().pagination.pageIndex;
  const pageNumbers = [];
  for (let i = 0; i < totalPages; i++) {
    pageNumbers.push(i);
  }
  if (currentPage <= 2) {
    return (
      <div className="flex items-center gap-1">
        {pageNumbers.slice(0, PAGE_COUNT_THRESHOLD).map((pageNumber) => (
          <div
            key={pageNumber}
            onClick={() => table.setPageIndex(pageNumber)}
            className={classNames(
              currentPage === pageNumber ? 'bg-active-pagination-color bg-gray-50' : '',
              'pagination-btn'
            )}
          >
            {pageNumber + 1}
          </div>
        ))}
        {totalPages > PAGE_COUNT_THRESHOLD && <span>...</span>}
        {totalPages > PAGE_COUNT_THRESHOLD && (
          <div
            className={classNames(
              currentPage === totalPages ? 'bg-active-pagination-color bg-gray-50' : '',
              'pagination-btn'
            )}
            onClick={() => table.setPageIndex(totalPages)}
          >
            {totalPages}
          </div>
        )}
      </div>
    );
  } else if (currentPage >= totalPages - 1) {
    return (
      <div className="flex items-center gap-1">
        <div
          className={classNames(currentPage === 1 ? 'bg-active-pagination-color bg-gray-50' : '', 'pagination-btn')}
          onClick={() => table.setPageIndex(0)}
        >
          1
        </div>
        {totalPages > PAGE_COUNT_THRESHOLD && <span>...</span>}
        {pageNumbers.slice(totalPages - PAGE_COUNT_THRESHOLD).map((pageNumber) => (
          <div
            key={pageNumber}
            onClick={() => table.setPageIndex(pageNumber)}
            className={classNames(
              currentPage === pageNumber ? 'bg-active-pagination-color bg-gray-50' : '',
              'pagination-btn'
            )}
          >
            {pageNumber + 1}
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="flex items-center gap-1">
        <div
          className={classNames(currentPage === 1 ? 'bg-active-pagination-color bg-gray-50' : '', 'pagination-btn')}
          onClick={() => table.setPageIndex(0)}
        >
          1
        </div>
        <span>...</span>
        {pageNumbers.slice(currentPage - 2, currentPage + 1).map((pageNumber) => (
          <div
            key={pageNumber}
            onClick={() => table.setPageIndex(pageNumber)}
            className={classNames(
              currentPage === pageNumber ? 'bg-active-pagination-color bg-gray-50' : '',
              'pagination-btn'
            )}
          >
            {pageNumber + 1}
          </div>
        ))}
        <span>...</span>
        <div
          className={classNames(
            currentPage === totalPages ? 'bg-active-pagination-color bg-gray-50' : '',
            'pagination-btn'
          )}
          onClick={() => table.setPageIndex(totalPages - 1)}
        >
          {totalPages}
        </div>
      </div>
    );
  }
};

export type PaginationProps<T> = {
  table: Table<T>;
  showPaginationNumbers?: boolean;
};

const Pagination = <T,>({ table, showPaginationNumbers = true }: PaginationProps<T>) => {
  const isMobileView = useIsMobileView();

  return (
    <div
      className={` flex w-full mx-auto items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-white rounded-b-lg ${
        isMobileView ? 'justify-center' : 'justify-between'
      }`}
    >
      {!isMobileView && (
        <Button
          displayType="secondary"
          customClass="gap-1 text-gray-700 cursor-pointer"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icons.ArrowLeft className="w-3 h-3 mr-1" aria-hidden="true" />
          Previous
        </Button>
      )}

      {showPaginationNumbers && (
        <div className="flex items-center gap-1">
          <div className="text-active-text-color flex">{renderPagination(table)}</div>
        </div>
      )}

      {!isMobileView && (
        <Button
          displayType="secondary"
          customClass="gap-1 text-gray-700 cursor-pointer"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
          <Icons.ArrowRight className="w-3 h-3" aria-hidden="true" />
        </Button>
      )}
    </div>
  );
};

export default Pagination;
