/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'PartnerAccountUserWithPartner', id: string, name: string, email: string, avatar?: string | null, tzName: string, partnerAccount: { __typename?: 'PartnerAccount', id: string, name: string, logo?: string | null } } };

export type FindAllPartnerAccountUsersQueryVariables = Types.Exact<{
  partnerAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllPartnerAccountUsersFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllPartnerAccountUsersOrderBy>;
}>;


export type FindAllPartnerAccountUsersQuery = { __typename?: 'Query', findAllPartnerAccountUsers: { __typename?: 'FindAllPartnerAccountUsersPaginatedResponse', count: number, users: Array<{ __typename?: 'PartnerAccountUser', id: string, name: string, email: string, avatar?: string | null, createdAt: any }> } };

export type FindAllPartnerCustomersQueryVariables = Types.Exact<{
  partnerAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllPartnerCustomersFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllPartnerCustomersOrderBy>;
}>;


export type FindAllPartnerCustomersQuery = { __typename?: 'Query', findAllPartnerCustomers: { __typename?: 'FindAllPartnerCustomersPaginatedResponse', count: number, customers: Array<{ __typename?: 'MerchantAccount', id: string, name: string, logo?: string | null, primaryPhone: string, status: Types.MerchantAccountStatus, createdAt: any, businessOwner?: { __typename?: 'MerchantAccountBusinessOwner', name: string, email: string, avatar?: string | null } | null }> } };


export const MeDocument = gql`
    query Me {
  me {
    id
    name
    email
    avatar
    partnerAccount {
      id
      name
      logo
    }
    tzName
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const FindAllPartnerAccountUsersDocument = gql`
    query FindAllPartnerAccountUsers($partnerAccountId: String!, $filters: FindAllPartnerAccountUsersFilters, $pagination: Pagination, $orderBy: FindAllPartnerAccountUsersOrderBy) {
  findAllPartnerAccountUsers(
    partnerAccountId: $partnerAccountId
    filters: $filters
    pagination: $pagination
    orderBy: $orderBy
  ) {
    users {
      id
      name
      email
      avatar
      createdAt
    }
    count
  }
}
    `;

/**
 * __useFindAllPartnerAccountUsersQuery__
 *
 * To run a query within a React component, call `useFindAllPartnerAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPartnerAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPartnerAccountUsersQuery({
 *   variables: {
 *      partnerAccountId: // value for 'partnerAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllPartnerAccountUsersQuery(baseOptions: Apollo.QueryHookOptions<FindAllPartnerAccountUsersQuery, FindAllPartnerAccountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllPartnerAccountUsersQuery, FindAllPartnerAccountUsersQueryVariables>(FindAllPartnerAccountUsersDocument, options);
      }
export function useFindAllPartnerAccountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllPartnerAccountUsersQuery, FindAllPartnerAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllPartnerAccountUsersQuery, FindAllPartnerAccountUsersQueryVariables>(FindAllPartnerAccountUsersDocument, options);
        }
export type FindAllPartnerAccountUsersQueryHookResult = ReturnType<typeof useFindAllPartnerAccountUsersQuery>;
export type FindAllPartnerAccountUsersLazyQueryHookResult = ReturnType<typeof useFindAllPartnerAccountUsersLazyQuery>;
export type FindAllPartnerAccountUsersQueryResult = Apollo.QueryResult<FindAllPartnerAccountUsersQuery, FindAllPartnerAccountUsersQueryVariables>;
export const FindAllPartnerCustomersDocument = gql`
    query FindAllPartnerCustomers($partnerAccountId: String!, $filters: FindAllPartnerCustomersFilters, $pagination: Pagination, $orderBy: FindAllPartnerCustomersOrderBy) {
  findAllPartnerCustomers(
    partnerAccountId: $partnerAccountId
    filters: $filters
    pagination: $pagination
    orderBy: $orderBy
  ) {
    customers {
      id
      name
      logo
      primaryPhone
      status
      businessOwner {
        name
        email
        avatar
      }
      createdAt
    }
    count
  }
}
    `;

/**
 * __useFindAllPartnerCustomersQuery__
 *
 * To run a query within a React component, call `useFindAllPartnerCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPartnerCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPartnerCustomersQuery({
 *   variables: {
 *      partnerAccountId: // value for 'partnerAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllPartnerCustomersQuery(baseOptions: Apollo.QueryHookOptions<FindAllPartnerCustomersQuery, FindAllPartnerCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllPartnerCustomersQuery, FindAllPartnerCustomersQueryVariables>(FindAllPartnerCustomersDocument, options);
      }
export function useFindAllPartnerCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllPartnerCustomersQuery, FindAllPartnerCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllPartnerCustomersQuery, FindAllPartnerCustomersQueryVariables>(FindAllPartnerCustomersDocument, options);
        }
export type FindAllPartnerCustomersQueryHookResult = ReturnType<typeof useFindAllPartnerCustomersQuery>;
export type FindAllPartnerCustomersLazyQueryHookResult = ReturnType<typeof useFindAllPartnerCustomersLazyQuery>;
export type FindAllPartnerCustomersQueryResult = Apollo.QueryResult<FindAllPartnerCustomersQuery, FindAllPartnerCustomersQueryVariables>;