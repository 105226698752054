import React, { FC } from 'react';

export interface Props {
  htmlFor?: string;
  label: string;
  customClass?: string;
}

const Label: FC<Props> = ({ label, htmlFor, customClass = '' }) => {
  return (
    <label className={`block text-sm font-medium text-steel-700 mb-2 label ${customClass}`} htmlFor={htmlFor}>
      {label}
    </label>
  );
};

export default Label;
