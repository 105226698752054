import React from 'react';

function DinersClub({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8384 19.3H18.4345C22.4869 19.3 25.7162 16.085 25.8429 12.1135V12.0504C25.7795 8.0159 22.4869 4.73784 18.4345 4.80088H15.5218C11.6594 4.92696 8.62004 8.20502 8.74668 12.1135C8.74668 16.0219 11.9126 19.2369 15.8384 19.3ZM10.0131 12.0504C10.0131 8.77235 12.7358 6.06165 16.0284 6.06165C19.321 6.06165 22.0437 8.77235 22.0437 12.0504C22.0437 15.3285 19.321 18.0392 16.0284 18.0392C12.7358 18.0392 10.0131 15.3285 10.0131 12.0504ZM16.9782 16.0849V8.07892C19.1943 8.64627 20.5874 10.8527 20.0175 13.059C19.6376 14.572 18.4978 15.7067 16.9782 16.0849ZM12.1026 11.0418C11.5327 13.2482 12.8624 15.5176 15.0786 16.0849V8.07892C13.6223 8.45716 12.4825 9.59187 12.1026 11.0418Z"
        fill="#0079BE"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default DinersClub;
