import React from 'react';

function Alipay({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        d="M8.8796 8.79828H4.12156C3.50193 8.79828 3 9.30483 3 9.9291V14.7275C3 15.3517 3.50193 15.8583 4.12156 15.8583H8.8796C9.49923 15.8583 10.0005 15.3517 10.0005 14.7275V9.9291C10.0012 9.30417 9.49923 8.79828 8.8796 8.79828Z"
        fill="#1677FF"
      />
      <path
        d="M8.76386 13.2316C8.48413 13.137 8.10785 12.9922 7.68925 12.8395C7.94054 12.3984 8.14158 11.8965 8.27383 11.3509H6.89371V10.8496H8.58398V10.5699H6.89371V9.73401H6.20397C6.08296 9.73401 6.08296 9.85437 6.08296 9.85437V10.5692H4.3735V10.849H6.08296V11.3502H4.67109V11.6299H7.40886C7.309 11.9778 7.1741 12.3051 7.01472 12.6021C6.1266 12.3065 5.1783 12.0671 4.58313 12.2145C4.20223 12.3091 3.95688 12.4777 3.81272 12.655C3.15142 13.4664 3.62557 14.699 5.02223 14.699C5.84819 14.699 6.64374 14.2348 7.26007 13.4697C8.17993 13.9154 10.0005 14.6812 10.0005 14.6812V13.59C10.0011 13.59 9.77233 13.5715 8.76386 13.2316ZM4.89196 14.2275C3.8028 14.2275 3.48075 13.3625 4.01905 12.8891C4.19892 12.729 4.52692 12.651 4.70151 12.6338C5.34825 12.5697 5.94739 12.8183 6.65432 13.1661C6.15768 13.8195 5.52482 14.2275 4.89196 14.2275Z"
        fill="white"
      />
      <path
        d="M18.2012 9.83189C18.2012 10.1969 18.4684 10.4429 18.8407 10.4429C19.213 10.4429 19.4802 10.1969 19.4802 9.83189C19.4802 9.47347 19.213 9.22086 18.8407 9.22086C18.4684 9.22086 18.2012 9.47347 18.2012 9.83189Z"
        fill="#1677FF"
      />
      <path d="M17.5267 9.37494H16.4171V14.5026H17.5267V9.37494Z" fill="black" />
      <path
        d="M14.4715 9.55083H12.9823L11.3178 14.5033H12.3435L12.6245 13.5338H14.3875L14.6547 14.5033H15.968L14.4715 9.55083ZM12.856 12.7257L13.5159 10.4429H13.5437L14.1686 12.7257H12.856Z"
        fill="black"
      />
      <path d="M19.3955 10.7379H18.2859V14.5033H19.3955V10.7379Z" fill="black" />
      <path
        d="M30.9934 10.7452L31 10.7379H29.9538L29.2932 13.028H29.2581L28.4996 10.7379H27.2564L28.7523 14.5172L28.1273 15.6692V15.697H29.1034L30.9934 10.7452Z"
        fill="black"
      />
      <path
        d="M22.1565 10.6605C21.7491 10.6605 21.433 10.8153 21.0746 11.1102V10.7379H19.9649V15.6969H21.0746V14.4676C21.2855 14.5238 21.4819 14.5522 21.7207 14.5522C22.7113 14.5522 23.6034 13.8215 23.6034 12.5221C23.6034 11.3562 22.9573 10.6605 22.1565 10.6605ZM21.4535 13.8566C21.3272 13.8566 21.2075 13.8427 21.0739 13.8003V11.7636C21.306 11.6022 21.4952 11.5248 21.7339 11.5248C22.1485 11.5248 22.4785 11.8548 22.4785 12.5578C22.4792 13.4565 21.9944 13.8566 21.4535 13.8566Z"
        fill="black"
      />
      <path
        d="M27.0382 13.5616V11.9884C27.0382 11.1314 26.5323 10.6605 25.6402 10.6605C25.0708 10.6605 24.678 10.759 23.9612 10.9766L24.1576 11.8409C24.8109 11.546 25.0986 11.4197 25.4008 11.4197C25.7658 11.4197 25.9278 11.6796 25.9278 12.0803V12.1081C24.6562 12.3468 24.2627 12.4804 24.0174 12.7264C23.8348 12.9089 23.7575 13.1688 23.7575 13.471C23.7575 14.1945 24.3196 14.5807 24.846 14.5807C25.2394 14.5807 25.5555 14.4332 25.9841 14.1098L26.0614 14.5033H27.1711L27.0382 13.5616ZM25.9285 13.5901C25.6336 13.7514 25.4649 13.8149 25.2685 13.8149C25.0014 13.8149 24.8327 13.639 24.8327 13.3586C24.8327 13.2535 24.8539 13.1476 24.9379 13.0637C25.0715 12.9301 25.3314 12.8315 25.9285 12.6914V13.5901Z"
        fill="black"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Alipay;
