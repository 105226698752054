import moment from 'moment-timezone';
import { Slot } from '../types/WeeklyAvailabilityType';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function isActivePath(path: string) {
  const sanitizedPath = window.location.pathname.replaceAll('#', '');
  if (path === '/') {
    return sanitizedPath === `/${path}` || sanitizedPath === path;
  }
  return sanitizedPath.startsWith(`${path}`);
}

export function getTimeOptions() {
  const startTime = moment('00:00', 'HH:mm');
  const endTime = moment('23:30', 'HH:mm');
  const times = [];

  while (startTime.isSameOrBefore(endTime)) {
    times.push(startTime.format('HH:mm'));
    startTime.add(30, 'minutes');
  }

  return times;
}

export function findSlotsWith24Hour(slots: Slot[]): Record<number, boolean> {
  return slots.reduce<Record<number, boolean>>((slotsWith24Hour, slot, index) => {
    slotsWith24Hour[index] = slot.start === '24:00' || slot.end === '24:00';
    return slotsWith24Hour;
  }, {});
}
export function getMonthName(monthNumber: number, format: 'long' | 'short' | '2-digit' = 'short') {
  const date = new Date();

  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', { month: format });
}
