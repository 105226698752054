import BadgeType from '@portal/ui/components/display/BadgeType';

export const contactStatusBadgeMappings: Record<string, BadgeType> = {
  LEAD: BadgeType.Primary,
  LOST: BadgeType.Secondary,
  CUSTOMER: BadgeType.Success,
  OPPORTUNITY: BadgeType.Custom,
};

export const colorMappings: Record<string, string> = {
  success: 'bg-green-100 text-green-800',
  primary: 'bg-blue-100 text-blue-800',
  secondary: 'bg-steel-100 text-steel-800',
  cancelled: 'bg-red-50 text-red-700',
  warning: 'bg-yellow-50 text-yellow-700',
  custom: 'bg-primary-100 text-primary-700',
  draft: 'bg-gray-100 text-gray-700',
};
