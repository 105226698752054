import React from 'react';

export type FieldFeedbackProps = {
  feedbackClasses?: string;
  transitionClasses?: string;
  errorCondition: boolean;
  feedbackCondition?: boolean;
  feedbackMessage?: string;
  errorMessage?: string;
};

const FieldFeedback = ({
  feedbackClasses,
  transitionClasses,
  errorCondition,
  feedbackCondition,
  feedbackMessage,
  errorMessage,
}: FieldFeedbackProps) => (
  <>
    {feedbackCondition && feedbackMessage && (
      <div className={transitionClasses}>
        <React.Fragment key="open1">
          <div className={`text-sm text-steel-600 ${feedbackClasses}`}>{feedbackMessage}</div>
        </React.Fragment>
      </div>
    )}
    {errorCondition && errorMessage && (
      <div className={transitionClasses}>
        <React.Fragment key="open">
          <div className={`text-sm text-red-500 ${feedbackClasses}`}>{errorMessage}</div>
        </React.Fragment>
      </div>
    )}
  </>
);

export default FieldFeedback;
