import React from 'react';

function Qiwi({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.71429 11.5673C9.71429 15.4393 12.7818 18.5971 16.5797 18.5971C17.0459 18.5971 17.512 18.5551 18.0476 18.5068C18.6449 18.453 19.3286 18.3914 20.195 18.3715C21.5097 18.3715 22.9339 18.8602 24.5042 20.2887C24.6502 20.4391 24.8693 20.2512 24.7233 20.0632C23.153 18.0708 21.7288 17.6949 20.3046 17.3565C18.5517 16.943 17.6387 15.9656 17.0179 14.8379C16.9084 14.6123 16.8353 14.6499 16.8353 14.9506C16.7988 15.4018 16.8353 15.8153 16.9084 16.2288H16.6893C14.206 16.2288 12.161 14.1236 12.161 11.5673C12.161 9.01107 14.206 6.9059 16.6893 6.9059C19.1725 6.9059 21.2175 9.01107 21.2175 11.5673C21.2175 11.7177 21.2175 11.9057 21.181 12.0936C20.8158 12.0184 20.195 12.0184 19.7203 12.056C19.5377 12.056 19.5377 12.1312 19.6838 12.1688C21.1445 12.4696 22.167 13.4094 22.4226 15.1386C22.4226 15.2138 22.4591 15.2138 22.4957 15.1762C23.0799 14.1236 23.4451 12.8831 23.4451 11.5673C23.4451 7.65775 20.3776 4.5 16.5797 4.5C12.7818 4.5 9.71429 7.69534 9.71429 11.5673ZM21.5461 14.462C21.8018 14.5747 21.9113 14.9507 21.9113 15.1386C21.9478 15.4394 21.8748 15.5897 21.7652 15.5897C21.6557 15.5897 21.5096 15.4769 21.3635 15.2138C21.2175 14.9507 21.1444 14.6875 21.2175 14.5371C21.327 14.462 21.4366 14.4244 21.5461 14.462ZM20.0489 15.6273C20.195 15.6273 20.3776 15.7025 20.5602 15.8529C20.8523 16.116 20.9619 16.4168 20.8158 16.6423C20.7427 16.7551 20.5602 16.8303 20.4141 16.8303C20.2315 16.8303 20.0489 16.7551 19.9393 16.6423C19.6472 16.3792 19.5742 16.0032 19.7568 15.7777C19.7933 15.7025 19.9028 15.6273 20.0489 15.6273Z"
        fill="#F28A1A"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Qiwi;
