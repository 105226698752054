import { ApolloClient, InMemoryCache, createHttpLink, from, Observable, FetchResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Authentication from '@portal/auth';
import { LocalStorage } from '@portal/local-storage';

interface CustomGraphQLError extends GraphQLError {
  errorType: string;
  message: string;
}

export default class Apollo {
  private client: ApolloClient<any>;
  private auth: Authentication;
  private localStorage: LocalStorage;

  constructor(poolData: any) {
    this.auth = new Authentication(poolData);
    this.localStorage = new LocalStorage();

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }): any => {
      if (graphQLErrors) {
        for (const err of graphQLErrors as CustomGraphQLError[]) {
          switch (err.errorType) {
            case 'UnauthorizedException':
              const observable = new Observable<FetchResult<Record<string, any>>>((observer) => {
                (async () => {
                  try {
                    const refreshSession: any = await this.auth.refreshSession();

                    if (!refreshSession.accessToken.jwtToken) {
                      throw new GraphQLError('Empty AccessToken');
                    }

                    this.localStorage.set('access-token', refreshSession.accessToken.jwtToken);
                    this.localStorage.set('refresh-token', refreshSession.refreshToken.token);

                    const { headers } = operation.getContext();
                    operation.setContext({
                      headers: {
                        ...headers,
                        authorization: refreshSession.accessToken.jwtToken,
                      },
                    });

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              });
              return observable;
          }
        }
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: this.localStorage.get('access-token') || '',
        },
      };
    });

    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GQL_API ?? '/graphql',
    });

    this.client = new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
      },
    });
  }

  getClient() {
    return this.client;
  }
}
