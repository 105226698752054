import Button from '../../base/Button';
import Icons from '../../assets/icons';

export interface EmptyStateProps<T> {
  title: string;
  subTitle?: string;
  btnText?: string;
  icon?: React.ReactNode;
  onClick?: (value: React.SetStateAction<boolean>) => void;
  customClassNames?: string;
  showBtnIcon?: boolean;
}

const EmptyState = ({
  title,
  subTitle,
  btnText,
  icon,
  onClick,
  customClassNames,
  showBtnIcon = true,
}: EmptyStateProps<any>) => {
  return (
    <div className={`col-center py-32 ${customClassNames}`}>
      {icon && (
        <div className="inline-flex items-center justify-center rounded-full w-10 h-10 text-white p-2 mb-4 bg-emptyStateBg">
          {icon}
        </div>
      )}
      <div className="text-base font-semibold text-gray-900">{title}</div>
      <div className="text-sm font-normal text-gray-600 w-96 text-center mb-6">{subTitle}</div>
      {btnText && (
        <Button title="empty-state" displayType="primary" onClick={() => onClick && onClick(true)}>
          {showBtnIcon && <Icons.Plus className="w-5 h-5 ml-1" aria-hidden="true" color="white" />}
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
