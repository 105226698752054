import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

export const CircleFilled = ({
  width = ICON_WIDTH,
  height = ICON_HEIGHT,
  color = ICON_COLOR,
  className = '',
}: IconProps) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={color} />
    </svg>
  );
};
