class LocalStorage {
  private readonly memoryStore: Record<string, string | null | undefined>;
  constructor() {
    this.memoryStore = {};
  }

  isLocalStorageAvailable() {
    if (typeof localStorage !== 'undefined') {
      try {
        localStorage.getItem('foo');
        return true; // available & enabled
      } catch (e) {
        return false; // disabled
      }
    }
    return false; // unavailable
  }

  get(key: string): string | null {
    if (this.isLocalStorageAvailable()) {
      return localStorage.getItem(key);
    }
    return this.memoryStore[key] ?? null;
  }

  getJson<T = Record<string, unknown>>(key: string): T | null {
    const value = this.get(key);
    return value ? JSON.parse(value) : null;
  }

  set(key: string, value: string | null) {
    if (value == null) {
      return this.remove(key);
    }
    if (this.isLocalStorageAvailable()) {
      localStorage.setItem(key, value);
      return;
    }
    this.memoryStore[key] = value;
  }

  setJson(key: string, value: any) {
    if (value == null) {
      return this.remove(key);
    }
    if (this.isLocalStorageAvailable()) {
      localStorage.setItem(key, JSON.stringify(value));
      return;
    }
    this.memoryStore[key] = JSON.stringify(value);
  }

  mergeJson(key: string, value: any) {
    const current = this.getJson(key) ?? {};
    this.setJson(key, { ...current, ...(value ?? {}) });
  }

  remove(key: string) {
    if (this.isLocalStorageAvailable()) {
      localStorage.removeItem(key);
      return;
    }
    delete this.memoryStore[key];
  }

  clear() {
    if (this.isLocalStorageAvailable()) {
      localStorage.clear();
      return;
    }
    for (const key in this.memoryStore) delete this.memoryStore[key];
  }
}

export { LocalStorage };
