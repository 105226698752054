import { Helmet } from 'react-helmet';
export type SubHeadingProps = {
  title?: string;
  description?: string;
  customClass?: string;
  actions?: React.ReactNode[];
  showBorder?: boolean;
  useHelmetTitle?: boolean;
  info?: string;
  variant?: 'small' | 'medium' | 'large';
};

export const SubHeading = ({
  title,
  description,
  customClass = '',
  actions,
  showBorder = false,
  useHelmetTitle = false,
}: SubHeadingProps) => {
  return (
    <>
      {useHelmetTitle && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <div className={`flex gap-1 py-6 ${showBorder ? ' border-b border-steel-200' : ''}`}>
        <div className="flex-grow">
          <h1 className={`text-lg font-semibold text-steel-900 ${customClass}`}>{title}</h1>
          {description && <p className="text-sm text-steel-500 break-all">{description}</p>}
        </div>
        {actions && (
          <div className="flex">
            {actions.map((item, idx) => (
              <div key={idx} className="flex-none ml-2">
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
