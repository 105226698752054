const ternary = <A, B, C>(
  condition: boolean | undefined | null,
  _true: A,
  _false: B,
  nullish?: C
) => {
  if (condition == null) {
    return nullish;
  }
  return condition ? _true : _false;
};

export { ternary };
