import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const Growthwearbooks = ({
  width = ICON_WIDTH,
  height = ICON_HEIGHT,
  color = ICON_COLOR,
  className = '',
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 2.5V1M18.4393 3.56066L19.5 2.5M19.5103 7.5H21.0103M20.9506 12C20.4489 17.0533 16.1853 21 11 21C5.47715 21 1 16.5228 1 11C1 5.81465 4.94668 1.5511 10 1.04938M11 7H15V11M14.6197 7C12.2653 10.3276 8.38636 12.5 4 12.5C2.9971 12.5 2.02072 12.3864 1.08302 12.1715"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Growthwearbooks;
