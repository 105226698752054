import { Form } from 'formik';
import type { FormikConfig } from 'formik';
import { Button, Drawer, Formik } from '@portal/ui';
import { newCustomerSchema } from '../validation/customerSchema';
import { CustomerFormFields } from './CustomerFormFields';

type FormState = {
  businessName: string;
  businessPrimaryPhone: string;
  businessOwnerName: string;
  businessOwnerEmail: string;
  welcomeMessage: string;
};

const defaultInitialValues: FormState = {
  businessName: '',
  businessPrimaryPhone: '',
  businessOwnerName: '',
  businessOwnerEmail: '',
  welcomeMessage: '',
};

type CustomerFormProps = {
  mode?: 'create' | 'edit';
  initialValues?: Partial<FormState>;
  loading?: boolean;
  onSubmit: FormikConfig<FormState>['onSubmit'];
};

const CustomerForm = ({ mode = 'create', initialValues, loading, onSubmit }: CustomerFormProps) => {
  return (
    <Formik<FormState>
      initialValues={{ ...defaultInitialValues, ...initialValues }}
      validationSchema={newCustomerSchema}
      onSubmit={onSubmit}
    >
      <Form className="h-full flex flex-col">
        <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
          <CustomerFormFields />
        </div>
        <Drawer.Footer>
          <Button title="invite or save customer" displayType="primary" type="submit" loading={loading}>
            {mode === 'create' ? 'Invite' : 'Save'}
          </Button>
        </Drawer.Footer>
      </Form>
    </Formik>
  );
};

export { CustomerForm };
export type { CustomerFormProps, FormState };
