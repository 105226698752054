import { getIn, FieldMetaProps } from 'formik';
import { toast } from 'react-hot-toast';

export const checkFieldErrors = (
  touched: Record<string, FieldMetaProps<any>['touched']>,
  errors: Record<string, FieldMetaProps<any>['error']>,
  fieldPath: string | undefined,
  propertyName: string
) => {
  const path = fieldPath ? `${fieldPath}.${propertyName}` : propertyName;
  return Boolean(getIn(errors, path) && getIn(touched, path));
};

export const passwordValidation = (nPassword: string, cPassword: string) => {
  let result = false;
  if (!nPassword && !cPassword) {
    toast.error('Must fill all the fields');
  } else if (nPassword !== cPassword) {
    toast.error("Password didn't match");
  } else {
    return (result = true);
  }
};
