import { Menu, Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren } from 'react';

export type DropdownItem = {
  name: string;
  active?: boolean;
  action?: (event: any) => void;
};

export type DropdownProps = {
  items: DropdownItem[];
  icon?: React.ReactNode;
  heading?: string;
  classNames?: string;
  customClassNames?: {
    button?: string;
    items?: string;
    item?: string;
  };
};

export default function Dropdown({
  items,
  icon,
  heading,
  children,
  classNames = '',
  customClassNames,
}: PropsWithChildren<DropdownProps>) {
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left w-full">
        <div className="w-full">
          {heading ? (
            <Menu.Button className={`dropDown-menu px-4 py-2 ${classNames}`}>
              {children ? (
                children
              ) : (
                <>
                  {icon && icon}
                  {heading && <div>{heading}</div>}
                </>
              )}
            </Menu.Button>
          ) : (
            <Menu.Button className={`dropDown-menu p-1 ${classNames}`}>
              {children ? children : <>{icon && icon}</>}
            </Menu.Button>
          )}
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="dropDown-items absolute w-60 right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            {items.map((item, idx: number) => (
              <div key={idx} className={`${customClassNames?.item} p-1`}>
                <Menu.Item>
                  {() => (
                    <button
                      onClick={item.action}
                      className={`dropdown-item text-gray-900 group flex w-full items-center rounded-md  py-2 text-sm`}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
