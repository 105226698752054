import { Helmet } from 'react-helmet';
import { Header } from './Header';

const Layout = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <Helmet titleTemplate="%s | Growthware">
        <title></title>
      </Helmet>
      <div className="min-h-full">
        <Header />
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
};

export { Layout };
