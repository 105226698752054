import React from 'react';

function Lightcoin({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25 12C25 16.4183 21.4183 20 17 20C12.5817 20 9 16.4183 9 12C9 7.58172 12.5817 4 17 4C21.4183 4 25 7.58172 25 12ZM14.8284 12.594L15.9562 8.3638H18.4004L17.5543 11.6541L18.6825 11.1841L18.4004 12.2179L17.2722 12.594L16.7083 14.6622H20.7507L20.3746 16.166H13.888L14.5462 13.6279L13.6063 14.0039L13.888 12.9701L14.8284 12.594Z"
        fill="#A5A8A9"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Lightcoin;
