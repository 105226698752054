import React from 'react';

function Etherium({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <ellipse cx="16.935" cy="11.935" rx="7.935" ry="7.93499" fill="#6481E7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4684 12.2578L17.0278 14.362V6.35181L13.4684 12.2578Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.028 6.35181V14.362L20.5874 12.2578L17.028 6.35181Z"
        fill="#C1CCF5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.028 10.6401L13.4685 12.2577L17.0278 14.3619L20.5874 12.258L17.028 10.6401Z"
        fill="#8299EC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.028 10.6401L13.4685 12.2577L17.0278 14.3619L17.028 10.6401Z"
        fill="#C1CCF5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4685 12.9329L17.0278 17.9489V15.0359L13.4685 12.9329Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.028 15.0359V17.9491L20.5897 12.9329L17.028 15.0359Z"
        fill="#C1CCF5"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Etherium;
