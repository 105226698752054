import { useState } from 'react';
import { getFormData, FormDataState } from '@portal/utils/forms';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { useDispatch, useSelector } from 'redux/hooks';
import { Formik } from '@portal/ui';
import { profileSchema } from 'components/validation/profileSchema';
import ProfileFields from 'components/profile/ProfileFields';
import { useRequestPartnerUserAvatarUpdateMutation, useUpdatePartnerUserMutation } from 'graphql/mutation.generated';
import { User } from 'types/User';
import { fetchUserAttributes } from 'redux/thunks/user';

export type FormState = {
  name: string;
  email: string;
  avatar: string;
  tzName: string;
};

const ProfileForm = () => {
  const attributes = useSelector((state) => state.user.attributes);
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [requestPartnerLogoUpdateMutation] = useRequestPartnerUserAvatarUpdateMutation();

  const [updatePartnerUserMutation] = useUpdatePartnerUserMutation({
    onCompleted: () => {
      toast.success('Profile updated');
      dispatch(fetchUserAttributes());
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const requestAvatarUpdate = (values: User) => {
    requestPartnerLogoUpdateMutation({
      variables: {
        imgMimeType: values?.avatar?.type || '',
      },
    }).then((res) => {
      const uploadDetails = res?.data?.requestPartnerUserAvatarUpdate;
      const formData = getFormData(uploadDetails as FormDataState, values.avatar as File);

      fetch(uploadDetails?.formPostUrl || '', {
        method: 'POST',
        body: formData,
      }).then(async (res) => {
        updatePartnerUserMutation({
          variables: {
            data: {
              acknowledgePartnerUserAvatarUpdate: true,
              name: values.name,
              tzName: values.tzName,
            },
          },
        });
        setIsUpdating(false);
      });
    });
  };

  const updateUserProfile = async (values: User) => {
    if (values.avatar && values.avatar.type) {
      setIsUpdating(true);
      requestAvatarUpdate(values);
      return;
    }
    if (values.name || values.tzName) {
      setIsUpdating(true);
      await updatePartnerUserMutation({
        variables: {
          data: {
            name: values.name,
            tzName: values.tzName,
          },
        },
      });
    }
    setIsUpdating(false);
  };

  type FormState = {
    name: string;
    avatar: string | File;
    tzName: string;
  };

  const initialValues: FormState = {
    name: attributes?.name ?? '',
    avatar: attributes?.avatar ?? '',
    tzName: attributes?.tzName ?? '',
  };

  return (
    <Formik<FormState>
      initialValues={initialValues}
      validationSchema={profileSchema}
      enableReinitialize
      onSubmit={(values) => {
        updateUserProfile(values as unknown as User);
      }}
    >
      <ProfileFields loading={isUpdating} />
    </Formik>
  );
};

export default ProfileForm;
