import { PropsWithChildren } from 'react';
import Button from '../../base/Button';
import Modal from '../../base/Modal';

interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: string;
  title?: string;
  isLoading?: boolean;
}

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  content,
  children,
  title,
  isLoading = false,
}: PropsWithChildren<ConfirmationModalProps>) => {
  return (
    <Modal
      open={open}
      title={title || ''}
      onClose={onClose}
      actions={[
        <Button title={`Cancel ${title}`} displayType="secondary" onClick={onClose}>
          Cancel
        </Button>,
        <Button title={`Confirm ${title}`} displayType="primary" onClick={onConfirm} loading={isLoading}>
          Confirm
        </Button>,
      ]}
      subTitle={content}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
