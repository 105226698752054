import { useState, useEffect } from "react";
import debounce from "lodash/debounce";
const useIsMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    const debouncedHandleResize = debounce(handleResize, 100);
    debouncedHandleResize();
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return isMobileView;
};

export { useIsMobileView };
