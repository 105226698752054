import { PageHeader, VerticalTabs } from '@portal/ui';
import General from 'components/settings/general/General';
import Teams from 'components/settings/Team';

const SettingsPage = () => {
  const tabsData = [
    {
      id: 'general',
      name: 'General',
      component: <General />,
    },

    {
      id: 'team',
      name: 'Team',
      component: <Teams />,
    },
  ];
  return (
    <>
      <div className="py-8 border-b border-gray-200">
        <PageHeader title="Account settings" />
      </div>
      <VerticalTabs tabsData={tabsData} />
    </>
  );
};

export default SettingsPage;
