import { LocalStorage } from '@portal/local-storage';

const Keys = {
  accessToken: 'access-token',
  refreshToken: 'refresh-token',
} as const;

const localStorage = new LocalStorage();

export { Keys, localStorage };
