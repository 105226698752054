import { ChangeEvent, useState, useRef, useCallback } from 'react';
import { useDrop } from '@react-aria/dnd';
import Icons from '../../assets/icons';
import classNames from 'classnames';

export type FileUploadProps = {
  name?: string;
  onChange: (file: File | File[]) => void;
  allowedFileTypes?: string[];
  maxImageSize?: number;
  uploadInfoText?: string;
  multiple?: boolean;
  id?: string;
};

export const FILE_TYPES = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif'];
export const MAX_IMAGE_SIZE = 800 * 400;

const FileUpload = ({
  name = 'upload',
  onChange,
  allowedFileTypes = FILE_TYPES,
  maxImageSize = MAX_IMAGE_SIZE,
  uploadInfoText,
  multiple = false,
  id,
}: FileUploadProps) => {
  const uploaderRef = useRef(null);
  const [fileError, setFileError] = useState<string>('');

  const validateAndSubmit = useCallback(
    (event: FileList, file: File) => {
      if (!multiple && event.length > 1) {
        setFileError('Only one file can be uploaded at a time.');
        return;
      } else if (!allowedFileTypes.includes(file.type)) {
        setFileError('Invalid file type. Please upload an SVG, PNG, JPG or GIF.');
        return;
      } else if (
        (!multiple && file.size > maxImageSize) ||
        (multiple &&
          Array.from(event).every((item) => {
            return item.size > maxImageSize;
          }))
      ) {
        setFileError('File size exceeds maximum.');
        return;
      }
      onChange(multiple ? [...event] : file);
      setFileError('');
    },
    [allowedFileTypes, maxImageSize, onChange]
  );

  const onDrop = useCallback(
    async (event: any) => {
      const file = event.items[0];
      if (!file) return;

      validateAndSubmit(event.items, await file.getFile());
    },
    [validateAndSubmit]
  );

  const handleUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event?.target?.files;
      if (!files?.[0]) return;

      validateAndSubmit(files, files?.[0]);
      event.target.value = '';
    },
    [validateAndSubmit]
  );

  const { dropProps, isDropTarget } = useDrop({
    ref: uploaderRef,
    onDrop,
  });

  return (
    <>
      <div {...dropProps} ref={uploaderRef} className="file-upload">
        <label
          htmlFor={id}
          className={classNames(
            'col-center border-2 border-gray-300 border-dashed rounded-md hover:border-primary-300 bg-white h-32 w-full cursor-pointer gap-3 label',
            { 'border-primary': isDropTarget }
          )}
        >
          <div className="flex rounded-full bg-gray-100 w-10 h-10 text-white p-2 justify-center items-center">
            <Icons.FileUpload />
          </div>
          <div className="text-sm font-normal text-gray-600 text-center info-text">
            <span className="font-semibold text-primary">Click to upload </span> or drag and drop
            <div className="text-xs font-normal  text-center"> {uploadInfoText || 'PNG, JPG, or GIF (10MB max)'}</div>
          </div>
          <input
            onChange={handleUpload}
            type="file"
            id={id}
            name={name}
            multiple={multiple}
            className="hidden"
            accept={allowedFileTypes.join(',')}
          />
        </label>
      </div>
      {fileError && <div className="text-sm font-normal text-red-500 mt-2">{fileError}</div>}
    </>
  );
};

export default FileUpload;
