import { words } from "lodash";

const capitalizeText = (input: string) => {
  if (input === null || input === undefined) {
    return "";
  }
  return input.toLowerCase().replace(/^./, input[0].toUpperCase());
};

const getInitials = (input: string) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  const initials = [...input?.matchAll(rgx)] || [];
  return (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
};

const getFirstAndLastName = (input: string) => {
  return input !== "" ? words(input) : ["", ""];
};

const shuffle = (str: string) => {
  const chars = str.split("");
  for (let i = chars.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [chars[i], chars[j]] = [chars[j], chars[i]];
  }
  return chars.join("");
};

export { capitalizeText, getInitials, getFirstAndLastName, shuffle };
