import { TextField } from '@portal/ui';

const CustomerFormFields = () => {
  return (
    <>
      <TextField id="businessName" label="Business name" />
      <TextField id="businessPrimaryPhone" label="Primary phone number" />
      <TextField id="businessOwnerName" label="Business owner name" />
      <TextField id="businessOwnerEmail" label="Business owner email" type="email" />
      <TextField id="welcomeMessage" label="Welcome message" type="textarea" rows={5} help="Optional" />
    </>
  );
};

export { CustomerFormFields };
