import React, { useEffect } from 'react';
import _ from 'lodash';
import { useComboBoxState, useSearchFieldState } from 'react-stately';
import { useComboBox, useButton, useSearchField } from 'react-aria';
import Icons from '../../assets/icons';

export interface SearchFieldProps<T> {
  id?: string;
  label?: string;
  placeholder?: string;
  customClass?: string;
  onChange: (value: string) => void;
  ariaLabel?: string;
}

const SearchField = <T extends object>(props: SearchFieldProps<T>) => {
  const state = useComboBoxState({ shouldCloseOnBlur: false, ...props });

  const inputRef = React.useRef(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { inputProps, labelProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state
  );

  const searchProps = {
    label: props.label,
    value: state.inputValue,
    onChange: (v: string) => state.setInputValue(v),
  };

  const searchState = useSearchFieldState(searchProps);
  const { clearButtonProps } = useSearchField(searchProps, searchState, inputRef);
  const clearButtonRef = React.useRef(null);
  const { buttonProps } = useButton(clearButtonProps, clearButtonRef);
  const outerRef = React.useRef(null);

  const handleInputChange = () => {
    props.onChange(state.inputValue);
  };

  _.debounce(handleInputChange, 1000);

  useEffect(() => {
    handleInputChange();
  }, [state.inputValue]);

  return (
    <div className={`inline-flex flex-col relative ${props.customClass}`}>
      <label {...labelProps} className="block text-sm font-medium text-gray-700 text-left">
        {props.label}
      </label>
      <div
        ref={outerRef}
        className={`search-input relative px-4 inline-flex flex-row items-center rounded-lg overflow-hidden shadow-sm !border-2 ${
          state.isFocused ? '!border-primary' : 'border-transparent'
        }`}
      >
        <Icons.Search aria-hidden="true" className="w-5 h-5 text-gray-500" />
        <input
          {...inputProps}
          ref={inputRef}
          className="px-3 py-1.5 w-full border-0 border-transparent focus:border-transparent focus:ring-0"
          placeholder={props.placeholder}
          id={props.id}
          aria-label={props.ariaLabel}
        />
        <button
          {...buttonProps}
          ref={clearButtonRef}
          style={{ visibility: state.inputValue !== '' ? 'visible' : 'hidden' }}
          className="cursor-default text-gray-500 hover:text-gray-600"
        >
          <Icons.Cross aria-hidden="true" className="w-5 h-5 cursor-pointer text-gray-500" />
        </button>
      </div>
    </div>
  );
};
export default SearchField;
