import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { loginSchema } from 'components/validation/loginSchema';
import logo from 'assets/growthware_logo_high_res.png';
import { Button, TextField } from '@portal/ui';
import { useDispatch, useSelector } from 'redux/hooks';
import { signInWithEmail } from 'redux/thunks/user';

const LoginPage = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.session.status);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const errorPresent = error && error !== '';

  const signInClicked = async (username: string, password: string) => {
    try {
      await dispatch(signInWithEmail(username, password));
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('verify');
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    if (status === 'signed-in') {
      navigate('/');
    }
  }, [status]);

  return (
    <div className="flex min-h-screen flex-col bg-primary-background justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">Log in to your account</h2>
        <p className="mt-2 text-center text-sm text-gray-400">Welcome back! Please enter your details.</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-primary-background-100 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {errorPresent && (
            <div className="border-red-400 bg-red-50 p-4 my-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
              signInClicked(values.email, values.password);
            }}
          >
            <Form className="space-y-6">
              <TextField
                customStyling={{ label: 'login-form-label', field: 'login-form-input' }}
                id="email"
                label="Email"
                type="email"
              />
              <TextField
                customStyling={{ label: 'login-form-label', field: 'login-form-input' }}
                id="password"
                label="Password"
                type="password"
              />
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 bg-primary-background-200 text-purple-600 focus:ring-purple-500"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-white">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link to="/passwords/new" className="font-medium text-purple-600 hover:text-purple-500">
                    Forgot password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  className="text-sm rounded-md shadow-sm font-medium items-center inline-flex px-4 py-2 border border-steel-300 cursor-pointer gap-1 !bg-primary-600 !primary-btn hover:!bg-primary-600 !border-primary-600 !hover:border-primary-700 !focus:ring-primary-500 !text-white"
                  type="submit"
                >
                  Log in
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
