export type TitleProps = {
  children?: React.ReactNode;
  customClasses?: string;
};

const Title = ({ children, customClasses }: TitleProps) => {
  return <h1 className={`title text-3xl font-semibold leading-6 text-steel-900 ${customClasses}`}>{children}</h1>;
};

export default Title;
