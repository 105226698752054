import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const Bell = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = ICON_COLOR, className = '' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79514 17.5C7.38275 18.0186 8.15462 18.3333 9 18.3333C9.84537 18.3333 10.6172 18.0186 11.2048 17.5M14 6.66666C14 5.34057 13.4732 4.0688 12.5355 3.13112C11.5978 2.19344 10.3261 1.66666 9 1.66666C7.67391 1.66666 6.40214 2.19344 5.46446 3.13112C4.52678 4.0688 4 5.34057 4 6.66666C4 9.24181 3.35039 11.005 2.62472 12.1712C2.0126 13.1549 1.70654 13.6467 1.71777 13.784C1.73019 13.9359 1.76238 13.9938 1.88481 14.0846C1.99538 14.1667 2.49382 14.1667 3.49071 14.1667H14.5093C15.5062 14.1667 16.0046 14.1667 16.1152 14.0846C16.2376 13.9938 16.2698 13.9359 16.2822 13.784C16.2934 13.6467 15.9874 13.1549 15.3753 12.1712C14.6496 11.005 14 9.24181 14 6.66666Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bell;
