import type { Dispatch, SetStateAction } from "react";
import { useState, useCallback } from "react";

/**
 * Used to maintain a state of type `object` and returns the following tuple:
 * 1. `state` object
 * 2. an `updater` function that may be used to shallow merge the state
 * 2. a `setter` function that may be used to set/replace the state
 */
const useMergeState = <T extends Record<string, unknown>>(initial: T) => {
  const [state, setState] = useState<T>(initial);

  /**
   * Shallow merge the state with previous state
   */
  const updateState = useCallback(
    (updateValueOrFn: Partial<T> | ((data: T) => Partial<T>)) => {
      if (typeof updateValueOrFn === "function") {
        setState((prev) => ({ ...prev, ...updateValueOrFn(prev) }));
      } else {
        setState((prev) => ({ ...prev, ...updateValueOrFn }));
      }
    },
    []
  );

  return [state, updateState, setState] as const;
};

export { useMergeState };
