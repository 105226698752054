import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, EmptyState, SubHeading, Table, Icons, Avatar } from '@portal/ui';
import { useMergeState } from '@portal/react-hooks/use-merge-state';
import type { SortingState, PaginationState } from '@tanstack/react-table';
import { ColumnType } from '@portal/ui/components/base/Table';
import { FindAllPartnerAccountUsersFilters } from 'graphql/types';
import { useSelector } from 'redux/hooks';
import { ternary } from '@portal/utils/conditional';
import debounce from 'lodash.debounce';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { getInitials, capitalizeText } from '@portal/utils/string';
import { useFindAllPartnerAccountUsersLazyQuery, FindAllPartnerAccountUsersQuery } from 'graphql/query.generated';

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllPartnerAccountUsersFilters | undefined;
};

const PAGE_SIZE = 10;

type UserRow = FindAllPartnerAccountUsersQuery['findAllPartnerAccountUsers']['users'][number];

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: undefined,
};

const Team = () => {
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const partnerAccountId = useSelector((state) => state.user.attributes?.partnerAccount.id);
  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const [findAllPartnerAccountUsersQuery, { data, loading, refetch }] = useFindAllPartnerAccountUsersLazyQuery({
    fetchPolicy: 'network-only', // Ignore cache and use server state
  });

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            nameLike: value,
            emailLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const onGlobalFilterChangeDebounced = useCallback(debounce(onGlobalFilterChange, 300), [onGlobalFilterChange]);

  const pageCount = useMemo(() => {
    const total = data?.findAllPartnerAccountUsers.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [data?.findAllPartnerAccountUsers.count]);

  useEffect(() => {
    if (partnerAccountId) {
      findAllPartnerAccountUsersQuery({
        variables: {
          partnerAccountId,
          pagination: {
            offset: tableState.pageIndex * tableState.pageSize,
            limit: tableState.pageSize,
          },
          filters: tableState.filters,
          orderBy: {
            name: ternary(tableState.sorting.find((col) => col.id === 'name')?.desc, 'desc', 'asc', undefined),
            createdAt: ternary(
              tableState.sorting.find((col) => col.id === 'createdAt')?.desc,
              'desc',
              'asc',
              undefined
            ),
          },
        },
        onError: (error) => {
          console.error(error);
          toast.error('Something went wrong');
        },
      });
    }
  }, [partnerAccountId, tableState.pageIndex, tableState.pageSize, tableState.filters, tableState.sorting]);

  const columns: ColumnType<UserRow>[] = [
    {
      id: 'name',
      name: 'Name',
      cell: (info: any) => {
        const member = info.row.original;
        return (
          <div className="flex items-center gap-3">
            <div className="h-10 w-10 flex-shrink-0">
              <Avatar imageUrl={member.avatar ?? ''} initials={getInitials(member.name)} />
            </div>
            <div>
              <div className="font-medium text-gray-900 text-sm">{member.name}</div>
              <div className="font-normal text-gray-600 text-sm">{member.email}</div>
            </div>
          </div>
        );
      },
    },

    {
      id: 'role',
      name: 'Role',
      cell: (info: any) => {
        return (
          <div className="flex items-center">
            <div className="font-normal text-gray-600 text-sm">Admin</div>
          </div>
        );
      },
    },
    {
      id: 'actions',
      name: '',
      cell: (info: any) => <div className="font-medium text-gray-900 text-sm">Edit</div>,
    },
  ];

  return (
    <>
      <SubHeading
        title="Team"
        description="Create and manage your team here."
        actions={[
          <Button title="add member" displayType="primary">
            Add member
          </Button>,
        ]}
      />
      <div className="py-8">
        <Table
          columns={columns}
          data={data?.findAllPartnerAccountUsers.users ?? []}
          pagination
          showSearch
          noDataElement={
            <EmptyState
              title={'No team members yet'}
              subTitle={'Get started by adding a new team member'}
              btnText={'New team member'}
              icon={<Icons.BuildingStore />}
            />
          }
          tableOptions={{
            manualPagination: true,
            manualSorting: true,
            pageCount,
            initialState: {
              pagination: {
                pageIndex: tableState.pageIndex,
                pageSize: tableState.pageSize,
              },
              sorting: tableState.sorting,
            },
            state: {
              pagination: {
                pageIndex: tableState.pageIndex,
                pageSize: tableState.pageSize,
              },
              sorting: tableState.sorting,
            },
            onPaginationChange: (getNewState) => {
              if (typeof getNewState === 'function') {
                updateTableState((old) =>
                  getNewState({
                    pageIndex: old.pageIndex,
                    pageSize: old.pageSize,
                  })
                );
              }
            },
            onSortingChange: (getNewState) => {
              if (typeof getNewState === 'function') {
                updateTableState((old) => ({ sorting: getNewState(old.sorting) }));
              }
            },
          }}
          loading={loading}
          onGlobalFilterChange={onGlobalFilterChangeDebounced}
        />
      </div>
    </>
  );
};

export default Team;
