import React from 'react';

function JCB({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        d="M21.9819 11.9543C22.5131 11.9658 23.0474 11.9308 23.5765 11.9725C24.1124 12.0725 24.2416 12.8837 23.7655 13.1494C23.4407 13.3244 23.0548 13.2146 22.7026 13.2454H21.9819V11.9543ZM23.8837 10.4929C24.0018 10.9095 23.6001 11.2836 23.1989 11.2262H21.9819C21.9904 10.8333 21.9652 10.4069 21.9942 10.0347C22.4818 10.0484 22.9738 10.0067 23.4585 10.0565C23.6668 10.1088 23.8411 10.28 23.8837 10.4929ZM26.8126 4.31454C26.8353 5.11018 26.8159 5.94788 26.8224 6.75965C26.8209 10.0601 26.8256 13.3604 26.8198 16.6609C26.7985 17.8978 25.7023 18.9726 24.4739 18.9973C23.2444 19.0023 22.0147 18.9981 20.785 18.9994V14.0099C22.1248 14.003 23.4653 14.024 24.8046 13.9995C25.4259 13.9605 26.1064 13.5505 26.1353 12.8668C26.2085 12.1802 25.5611 11.7052 24.9464 11.6302C24.7101 11.6241 24.7171 11.5613 24.9464 11.5339C25.5327 11.4072 25.993 10.8005 25.8205 10.1928C25.6735 9.55374 24.9671 9.30638 24.3796 9.3076C23.1816 9.29947 21.9833 9.30647 20.7852 9.3041C20.793 8.37262 20.7691 7.44014 20.7981 6.50934C20.893 5.29476 22.0168 4.29313 23.2279 4.31486C24.423 4.31468 25.6179 4.31468 26.8126 4.31463V4.31454Z"
        fill="url(#paint0_linear_6380_174601)"
      />
      <path
        d="M7.24651 6.63512C7.27711 5.40017 8.37798 4.33421 9.60474 4.31616C10.8297 4.31239 12.0549 4.31562 13.2799 4.31453C13.2765 8.44639 13.2865 12.5787 13.2748 16.7103C13.2277 17.9303 12.1388 18.9759 10.9254 18.9975C9.69808 19.002 8.47064 18.9982 7.24328 18.9994V13.8417C8.43545 14.1233 9.68562 14.2432 10.9018 14.0564C11.6288 13.9394 12.4242 13.5824 12.6704 12.8282C12.8515 12.183 12.7495 11.504 12.7765 10.8419V9.30408H10.6717C10.6622 10.3211 10.6911 11.3399 10.6565 12.3558C10.5997 12.9801 9.9814 13.3768 9.39253 13.3557C8.66222 13.3634 7.21501 12.8264 7.21501 12.8264C7.21137 10.9209 7.23619 8.5345 7.24651 6.6353V6.63512Z"
        fill="url(#paint1_linear_6380_174601)"
      />
      <path
        d="M14.0648 9.92439C13.954 9.9479 14.0425 9.54701 14.0142 9.39494C14.0218 8.43341 13.9984 7.47083 14.0271 6.50998C14.1217 5.29026 15.2542 4.28613 16.4701 4.31468H20.0511C20.0477 8.44654 20.0577 12.5789 20.0461 16.7105C19.9988 17.9304 18.9099 18.976 17.6964 18.9977C16.4691 19.0023 15.2416 18.9984 14.0142 18.9996V13.3486C14.8525 14.0364 15.9918 14.1435 17.0361 14.1453C17.8235 14.145 18.6062 14.0237 19.3708 13.8421V12.8068C18.5091 13.2362 17.4962 13.509 16.5411 13.2623C15.8748 13.0965 15.3911 12.4525 15.4019 11.7649C15.3247 11.0498 15.7438 10.2949 16.4465 10.0823C17.319 9.80915 18.2699 10.018 19.0877 10.3732C19.2629 10.4649 19.4408 10.5787 19.3707 10.2858V9.47209C18.0029 9.14667 16.5473 9.02692 15.1732 9.38094C14.7754 9.49314 14.3879 9.6633 14.0648 9.92449V9.92439Z"
        fill="url(#paint2_linear_6380_174601)"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
      <defs>
        <linearGradient
          id="paint0_linear_6380_174601"
          x1="20.7438"
          y1="11.343"
          x2="26.7843"
          y2="11.343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#58B03A" />
          <stop offset="1" stop-color="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6380_174601"
          x1="7.11485"
          y1="11.7976"
          x2="13.1809"
          y2="11.7976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0F6EB6" />
          <stop offset="1" stop-color="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6380_174601"
          x1="14.0053"
          y1="11.4854"
          x2="20.05"
          y2="11.4854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DE0D3D" />
          <stop offset="1" stop-color="#E30138" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default JCB;
