import { Auth, client } from 'libs/cognito';
import { logout, setAttributes, setStatus, updateSession } from '../../reducers/user';
import { ThunkFn } from '../definitions/types';
import { MeDocument, MeQuery } from 'graphql/query.generated';

const fetchUserAttributes: ThunkFn = () => {
  return async (dispatch) => {
    const res = await client.query<MeQuery>({ query: MeDocument, fetchPolicy: 'network-only' });
    dispatch(setAttributes(res.data.me));
  };
};

const signInWithTokens: ThunkFn = (accessToken: string, refreshToken: string) => {
  return async (dispatch) => {
    dispatch(updateSession({ accessToken, refreshToken }));
    dispatch(setStatus('signed-in'));
    await dispatch(fetchUserAttributes());
  };
};

const signInWithEmail: ThunkFn = (username: string, password: string) => {
  return async (dispatch) => {
    dispatch(setStatus('loading'));
    const session = await Auth.signInWithEmail(username, password);
    if (!session.isValid()) {
      throw new Error('Invalid session');
    }
    dispatch(signInWithTokens(session.getAccessToken().getJwtToken(), session.getRefreshToken().getToken()));
  };
};

const signOut: ThunkFn = () => {
  return (dispatch) => {
    Auth.signOut();
    dispatch(logout());
  };
};

export { fetchUserAttributes, signInWithTokens, signInWithEmail, signOut };
