import React from 'react';

function Elo({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        d="M7.82415 9.49573C8.11185 9.40141 8.41961 9.35019 8.73984 9.35019C10.1374 9.35019 11.3032 10.3241 11.5706 11.6178L13.551 11.2216C13.0966 9.0229 11.1155 7.3678 8.73984 7.3678C8.19594 7.3678 7.6724 7.45472 7.18369 7.61484L7.82415 9.49573Z"
        fill="#FECA2F"
      />
      <path
        d="M5.46727 15.8677L6.65306 14.3366C6.12376 13.8012 5.78968 13.0196 5.78968 12.1484C5.78968 11.2778 6.12344 10.4962 6.6526 9.96121L5.46618 8.43029C4.56678 9.33988 4 10.6685 4 12.1484C4 13.629 4.56761 14.9581 5.46727 15.8677Z"
        fill="#1BA7DE"
      />
      <path
        d="M11.5712 12.6802C11.3029 13.9737 10.1378 14.9464 8.74152 14.9464C8.42115 14.9464 8.11278 14.895 7.82506 14.8004L7.18369 16.6822C7.67316 16.8432 8.19686 16.9302 8.74152 16.9302C11.115 16.9302 13.0948 15.2762 13.551 13.0783L11.5712 12.6802Z"
        fill="#EC412A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0795 8.43029V13.8716L24.0559 14.2629L23.594 15.3362L22.6284 14.948C22.4112 14.857 22.2643 14.7183 22.1527 14.5616C22.0455 14.4013 21.966 14.1822 21.966 13.8865V8.43029H23.0795ZM15.6738 12.4744C15.6981 10.9184 17.0232 9.67627 18.6312 9.7001C19.996 9.7208 21.1267 10.6452 21.4237 11.8746L16.1453 14.0562C15.8387 13.6029 15.6644 13.0576 15.6738 12.4744ZM16.8816 12.6926C16.8743 12.6273 16.8692 12.5603 16.8712 12.4928C16.8863 11.576 17.6665 10.8445 18.6138 10.8597C19.1295 10.8663 19.5874 11.0947 19.8969 11.4481L16.8816 12.6926ZM19.7858 13.7028C19.4676 14.002 19.0362 14.1843 18.5599 14.1778C18.2334 14.1725 17.9309 14.078 17.6737 13.9205L17.036 14.903C17.4727 15.1699 17.9876 15.3278 18.5428 15.3361C19.3508 15.3478 20.0873 15.0408 20.6228 14.534L19.7858 13.7028ZM27.1434 10.8596C26.9532 10.8596 26.7704 10.8894 26.5996 10.9448L26.2197 9.84383C26.5097 9.75019 26.8202 9.69933 27.1434 9.69933C28.5537 9.69933 29.7302 10.6681 30 11.9549L28.8238 12.1868C28.6654 11.4294 27.9732 10.8596 27.1434 10.8596ZM25.2123 14.629L26.007 13.76C25.6521 13.4561 25.4286 13.0123 25.4286 12.5176C25.4286 12.0235 25.6521 11.5799 26.0067 11.2762L25.2114 10.4072C24.6085 10.9236 24.2286 11.678 24.2286 12.5176C24.2286 13.3583 24.6087 14.1125 25.2123 14.629ZM27.1433 14.1763C27.9724 14.1763 28.6644 13.6069 28.8238 12.8507L29.9996 13.0836C29.7285 14.369 28.5524 15.3364 27.1433 15.3364C26.8199 15.3364 26.509 15.2854 26.2182 15.1913L26.599 14.0908C26.7701 14.146 26.953 14.1763 27.1433 14.1763Z"
        fill="black"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default Elo;
