import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Icons from '../../assets/icons';

export type RowActionsProps<T = unknown> = {
  name: string;
  isDisabled?: boolean | ((row?: T) => boolean | undefined);
  action: (row?: T) => void;
};

export type RowActionMenuProps<T = unknown> = {
  rowActions: RowActionsProps<T>[];
  row?: T;
};

export function RowActionMenu<T = unknown>({ rowActions, row }: RowActionMenuProps<T>) {
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="pl-5 pr-5">
            <Icons.DotsVertical className="w-5 h-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
            {rowActions.map((rowAction, idx: number) => (
              <div key={idx} className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => rowAction.action(row)}
                      disabled={
                        typeof rowAction.isDisabled === 'function' ? rowAction.isDisabled(row) : rowAction.isDisabled
                      }
                      className={classNames(
                        'disabled:text-gray-300 group flex w-full items-center rounded-md px-2 py-2 text-sm text-black',
                        active ? 'bg-gray-100' : 'text-gray-900'
                      )}
                    >
                      {rowAction.name}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
