import { useRef } from 'react';
import { useDatePickerState } from 'react-stately';
import { useDatePicker, DateValue } from 'react-aria';
import { Calendar } from './Calendar';
import { DateField } from './DateField';
import Popover from '../../base/Popover';
import Dialog from '../../base/Dialog';
import Button from '../../base/Button';
import Icons from '../../assets/icons';
import { classNames } from '../../utils/helper';

interface DatePickerProps {
  onChange: (date: DateValue) => void;
  label?: string;
  minValue?: DateValue;
  value?: DateValue;
}

function DatePicker(props: DatePickerProps) {
  const state = useDatePickerState(props);
  const ref = useRef<HTMLDivElement>(null);

  const { groupProps, labelProps, fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(
    props,
    state,
    ref
  );
  return (
    <div className="relative inline-flex flex-col text-left">
      <span {...labelProps} className="text-sm text-gray-800">
        {props.label}
      </span>
      <div {...groupProps} ref={ref} className={`flex group`}>
        <Button
          customClass={classNames(
            '!rounded-md gap-2 border border-steel-300 group-hover:border-steel-300 !shadow-none focus:!ring-0 focus:!ring-offset-0 font-normal text-steel-700',
            state.validationState === 'invalid' ? '!border-red-500 group-hover:!border-red-500' : ''
          )}
          {...buttonProps}
          isPressed={state.isOpen}
        >
          <div
            className={classNames(
              'transition-colors rounded-l-md border-r-0 z-[-1] relative flex items-center gap-1',
              state.validationState === 'invalid' ? 'border-red-500 group-hover:border-red-500' : ''
            )}
          >
            <DateField {...fieldProps} />
            {state.validationState === 'invalid' && <Icons.Alert />}
          </div>
          <Icons.Calendar color={'#667085'} width={18} height={18} />
        </Button>
      </div>
      {state.isOpen && (
        <Popover triggerRef={ref} state={state}>
          <Dialog {...dialogProps}>
            <Calendar {...calendarProps} />
          </Dialog>
        </Popover>
      )}
    </div>
  );
}

export default DatePicker;
