import Select, { ValueContainerProps, OptionProps, components, DropdownIndicatorProps, ActionMeta } from 'react-select';
import Icons from '../../assets/icons';
import classNames from 'classnames';
import { Option } from '../../base/CreateableSelect';

export type SearchableSelectProps<T> = {
  options: T[];
  selectedComponent?: React.FC<ValueContainerProps>;
  handleChange: (
    value: unknown,
    action: ActionMeta<{
      value: string;
    }>
  ) => void;
  customOptionNode?: React.FC<OptionProps<T>>;
  placeholder?: string;
  isMulti?: boolean;
  controlShouldRenderValue?: boolean;
  isLoading?: boolean;
  value?: Option | Option[];
  defaultValue?: Option | Option[];
  hideSelectedOptions?: boolean;
  onInputChange?: (value: string) => void;
  id?: string;
  hasError?: boolean;
};

const DropdownIndicator = (props: DropdownIndicatorProps<unknown, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.ChevronDown />
    </components.DropdownIndicator>
  );
};

function SearchableInput<T>({
  options,
  selectedComponent,
  handleChange,
  customOptionNode,
  placeholder,
  isMulti,
  controlShouldRenderValue = true,
  onInputChange,
  hasError,
  ...props
}: SearchableSelectProps<T>) {
  return (
    <Select
      components={{
        ...(selectedComponent && { ValueContainer: selectedComponent }),
        ...(customOptionNode && { Option: customOptionNode }),
        IndicatorSeparator: () => null,
        DropdownIndicator: DropdownIndicator,
        LoadingIndicator: () => <Icons.Spinner />,
      }}
      classNames={{
        multiValue: () => 'bg-steel-50 mx-1',
        multiValueRemove: () => 'text-steel-500',
        valueContainer: () => 'text-md p-0.5',
        input: () => '[&>input]:text-red !text-red',
        placeholder: () => 'text-black text-sm ',
        menu: () => '!z-20',
        menuList: () => 'bg-white border mt-1 rounded-md',
        noOptionsMessage: () => '!text-steel-900 !text-sm p-2',
        indicatorSeparator: () => 'hidden',
        control: () =>
          classNames(
            'border bg-white border-steel-300 focus:border-steel-300 hover:border-steel-300 box-shadow-none !text-md sm:text-sm p-2 rounded-md',
            {
              '!border-red-300': hasError,
            }
          ),
        option: ({ isSelected }) =>
          classNames(
            'active:bg-steel-50 hover:text-steel-900 hover:bg-steel-50 !text-sm sm:text-sm  cursor-default rounded-md select-none px-3 py-2 active:text-steel-900 text-steel-800 !cursor-pointer bg-white'
          ),
      }}
      isMulti={isMulti ? true : undefined}
      unstyled
      onInputChange={onInputChange}
      closeMenuOnSelect
      controlShouldRenderValue={controlShouldRenderValue}
      options={options}
      placeholder={placeholder}
      noOptionsMessage={() => 'No options'}
      isClearable={false}
      onChange={handleChange}
      {...props}
    />
  );
}

export default SearchableInput;
