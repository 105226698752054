import { Button, ButtonGroup, Dropdown, SearchField, Table } from '@portal/ui';
import { TableProps } from '@portal/ui/components/base/Table';
import { PaginationState, SortingState, TableOptions } from '@tanstack/react-table';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import Icons from '../../assets/icons';
import { DropdownItem } from '../../base/Dropdown';

type DataTableProps<T> = TableProps<T> & {
  options?: TableOptions<T>;
  tableState: TableState;
  showSearch?: boolean;
  updateTableState: (updateValueOrFn: Partial<TableState> | ((data: TableState) => Partial<TableState>)) => void;
  pageCount?: number;
  onGlobalFilterChange: (value: string) => void;
  showViewModeFilter?: boolean;
  selectViewMode?: (viewMode: 'viewMine' | 'viewAll') => void;
  canAccessAll?: boolean;
  searchPlaceholder?: string;
  hideColumnHeadersOnMobile?: boolean;
};

type DataTablePropsWithViewMode<T> = DataTableProps<T> & {
  showViewModeFilter: true;
  selectViewMode: (viewMode: 'viewMine' | 'viewAll') => void;
  canAccessAll: boolean;
};

type DataTablePropsWithoutViewMode<T> = DataTableProps<T> & {
  showViewModeFilter?: false;
  selectViewMode?: (viewMode: 'viewMine' | 'viewAll') => void;
  canAccessAll?: boolean;
};

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: Partial<Filters> | undefined;
};

type Maybe<T> = T | null;

type Filters = {
  emailLike: Maybe<string>;
  nameLike: Maybe<string>;
  phoneLike: Maybe<string>;
  statusLike: Maybe<string>;
  viewMode: Maybe<'viewMine' | 'viewAll'>;
  siteTitleLike: Maybe<string>;
};

export default function DataTable<T>({
  options,
  updateTableState,
  tableState,
  pageCount,
  showViewModeFilter,
  selectViewMode,
  canAccessAll,
  onGlobalFilterChange,
  tableActions = [],
  filters,
  showSearch = false,
  searchPlaceholder,
  ...rest
}: DataTablePropsWithViewMode<T> | DataTablePropsWithoutViewMode<T>) {
  const onGlobalFilterChangeDebounced = useCallback(debounce(onGlobalFilterChange, 300), [onGlobalFilterChange]);

  return (
    <>
      <div className="hidden justify-between items-center md:flex table-action-header">
        {(showSearch || filters || (tableActions && tableActions.length > 0)) && (
          <div className="search-box w-full">
            {showSearch && (
              <SearchField
                customClass="w-search-btn"
                id="search-customer"
                placeholder={searchPlaceholder ?? 'Search '}
                onChange={onGlobalFilterChangeDebounced}
              />
            )}

            <div className="flex gap-3 items-center px-2">
              {filters && (
                <Dropdown
                  heading="Filters"
                  icon={<Icons.Filters className="w-3 h-3 ml-1" aria-hidden="true" />}
                  items={filters as DropdownItem[]}
                />
              )}
              {tableActions && tableActions.length > 0 && (
                <div className="flex">
                  <Button onClick={tableActions[0].action} displayType="primary" title="table action">
                    {tableActions[0].icon}
                    {tableActions[0].name}
                  </Button>
                  {tableActions.length > 1 && (
                    <button className="text-sm rounded-md shadow-sm font-medium items-center inline-flex px-4 py-2 border cursor-pointer gap-1 !hover:bg-primary-600 !bg-primary !primary-btn !border-primary-600 !hover:border-primary-700 !focus:ring-primary-500 !text-white secondary-table-actions">
                      <Dropdown icon={<Icons.ChevronDown className="chevron-down" />} items={tableActions.slice(1)} />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {showViewModeFilter && canAccessAll && (
          <ButtonGroup
            buttons={[
              {
                text: 'My contacts',
                onClick: () => selectViewMode('viewMine'),
                active: tableState.filters?.viewMode === 'viewMine',
                id: 'my-contacts',
              },
              {
                text: 'All contacts',
                onClick: () => selectViewMode('viewAll'),
                active: tableState.filters?.viewMode === 'viewAll',
                id: 'all-contacts',
              },
            ]}
          />
        )}
      </div>
      <Table
        {...rest}
        onGlobalFilterChange={onGlobalFilterChange}
        tableActions={tableActions}
        filters={filters}
        showSearch
        pagination={pageCount && pageCount > 1 ? true : false}
        tableOptions={{
          manualPagination: true,
          manualSorting: true,
          pageCount,
          initialState: {
            pagination: {
              pageIndex: tableState.pageIndex,
              pageSize: tableState.pageSize,
            },
            sorting: tableState.sorting,
          },
          state: {
            pagination: {
              pageIndex: tableState.pageIndex,
              pageSize: tableState.pageSize,
            },
            sorting: tableState.sorting,
          },
          onPaginationChange: (getNewState) => {
            if (typeof getNewState === 'function') {
              updateTableState((old: TableState) =>
                getNewState({
                  pageIndex: old.pageIndex,
                  pageSize: old.pageSize,
                })
              );
            }
          },
          onSortingChange: (getNewState) => {
            if (typeof getNewState === 'function') {
              updateTableState((old: TableState) => ({ sorting: getNewState(old.sorting) }));
            }
          },
          ...options,
        }}
      />
    </>
  );
}
