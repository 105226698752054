import type { CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';

interface CustomResponse extends CognitoUserSession {
  newPasswordRequired?: boolean;
}
export default class Authentication {
  private userPool: CognitoUserPool;
  private currentUser: CognitoUser | null;

  constructor(poolData: any) {
    this.userPool = new CognitoUserPool(poolData);
    this.currentUser = null;
    this.userPool.getCurrentUser();
  }

  getCurrentUser() {
    return this.currentUser;
  }

  getCognitoUser(username: string) {
    return new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
  }

  async getSession() {
    if (!this.currentUser) {
      this.currentUser = this.userPool.getCurrentUser();
    }
    return new Promise<CognitoUserSession>((resolve, reject) => {
      this.currentUser?.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  async refreshSession() {
    if (!this.currentUser) {
      this.currentUser = this.userPool.getCurrentUser();
    }

    return new Promise<CognitoUserSession>((resolve, reject) => {
      this.currentUser?.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        }

        if (!session.isValid()) {
          const refreshToken = session.getRefreshToken();

          if (!refreshToken) {
            reject(new Error('No refresh token available'));
          }

          const cognitoUser = new CognitoUser({
            Username: session.getIdToken().payload['cognito:username'],
            Pool: this.userPool,
          });

          cognitoUser.refreshSession(refreshToken, (err: Error | null, refreshedSession: CognitoUserSession) => {
            if (err) {
              reject(err);
            }

            this.currentUser = cognitoUser;
            resolve(refreshedSession);
          });
        } else {
          resolve(session);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  async signUpUserWithEmail(username: string, email: string, password: string) {
    return new Promise<ISignUpResult | undefined>((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({
          Name: 'email',
          Value: email,
        }),
      ];

      this.userPool.signUp(username, password, attributeList, [], function (err, res) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  async verifyCode(username: string, code: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);

      cognitoUser.confirmRegistration(code, true, function (err: any, result: any) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  async signInWithEmail(username: string, password: string) {
    return new Promise<CustomResponse>((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      this.currentUser = this.getCognitoUser(username);

      this.currentUser.authenticateUser(authenticationDetails, {
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err.message === 'User is disabled.' ? { message: 'User does not exist.' } : err);
        },
        newPasswordRequired: (userAttr) => {
          resolve({ ...userAttr, newPasswordRequired: true });
        },
      });
    });
  }

  async setNewPassword(newPassword: string) {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      this.currentUser?.completeNewPasswordChallenge(newPassword, null, {
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  signOut() {
    this.currentUser?.signOut();
  }

  async getAttributes() {
    return new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => {
      this.currentUser?.getUserAttributes(function (err, attributes) {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    });
  }

  async setAttribute(attribute: any) {
    return new Promise<string | undefined>((resolve, reject) => {
      const attributeList = [new CognitoUserAttribute(attribute)];

      this.currentUser?.updateAttributes(attributeList, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  sendCode(username: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`);
        return;
      }

      cognitoUser.forgotPassword({
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  forgotPassword(username: string, code: string, password: string) {
    return new Promise<string>((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`);
        return;
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function (res) {
          resolve(res);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  }

  changePassword(oldPassword: string, newPassword: string) {
    return new Promise<string | undefined>((resolve, reject) => {
      if (!this.currentUser) this.getSession();

      this.currentUser?.changePassword(oldPassword, newPassword, function (err, res) {
        if (err) {
          reject(
            err.message === 'Attempt limit exceeded, please try after some time.'
              ? { message: 'Attempt limit exceeded. Please wait and try again.' }
              : err.message === 'Incorrect username or password.'
              ? { message: 'Invalid password' }
              : err.message === 'Password did not conform with policy: Password must have lowercase characters'
              ? {
                  message:
                    'Password must be at least 8 characters and contain an uppercase, lowercase, number, and symbol character',
                }
              : err
          );
        } else {
          resolve(res);
        }
      });
    });
  }
}
