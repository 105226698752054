import React from 'react';

function PayPal({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6142 18.4483L14.8349 16.9991L14.3432 16.9873H11.9953L13.627 6.29367C13.6321 6.26129 13.6485 6.23123 13.6725 6.20984C13.6967 6.18846 13.7274 6.17673 13.7596 6.17673H17.7185C19.033 6.17673 19.94 6.45936 20.4135 7.0173C20.6355 7.27905 20.7769 7.55264 20.8453 7.85361C20.9171 8.16948 20.9183 8.54682 20.8483 9.00712L20.8432 9.0406V9.33558L21.0652 9.4656C21.2521 9.56812 21.4008 9.68543 21.5147 9.81972C21.7046 10.0436 21.8274 10.328 21.8793 10.6652C21.933 11.012 21.9153 11.4247 21.8274 11.892C21.7261 12.4294 21.5624 12.8976 21.3412 13.2805C21.1378 13.6334 20.8787 13.9262 20.5709 14.153C20.2772 14.3685 19.9282 14.5322 19.5335 14.6369C19.1511 14.7398 18.7151 14.7917 18.2368 14.7917H17.9287C17.7085 14.7917 17.4945 14.8737 17.3265 15.0207C17.158 15.1707 17.0467 15.3758 17.0125 15.6L16.9892 15.7305L16.5992 18.2848L16.5816 18.3785C16.5768 18.4082 16.5688 18.423 16.557 18.433C16.5465 18.4421 16.5313 18.4483 16.5166 18.4483H14.6142Z"
        fill="#28356A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.2756 9.07468C21.2639 9.15276 21.2503 9.23255 21.2352 9.31454C20.7131 12.0852 18.9269 13.0423 16.6456 13.0423H15.4841C15.2051 13.0423 14.9699 13.2517 14.9265 13.5361L14.1634 18.5394C14.1351 18.7262 14.2744 18.8945 14.4567 18.8945H16.5169C16.7608 18.8945 16.968 18.7113 17.0064 18.4626L17.0267 18.3545L17.4146 15.8103L17.4395 15.6707C17.4774 15.4212 17.6852 15.2379 17.9291 15.2379H18.2372C20.2331 15.2379 21.7957 14.4004 22.2524 11.9766C22.4431 10.9641 22.3444 10.1186 21.8396 9.52399C21.6868 9.34473 21.4973 9.19589 21.2756 9.07468Z"
        fill="#298FC2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7293 8.84962C20.6495 8.82555 20.5672 8.8038 20.4828 8.78413C20.3979 8.76494 20.311 8.74796 20.2215 8.73305C19.9083 8.68075 19.5651 8.65594 19.1975 8.65594H16.0945C16.018 8.65594 15.9454 8.67379 15.8805 8.70604C15.7373 8.77716 15.6311 8.9172 15.6053 9.08864L14.9451 13.4102L14.9262 13.5362C14.9696 13.2517 15.2047 13.0424 15.4838 13.0424H16.6453C18.9266 13.0424 20.7128 12.0847 21.2349 9.31457C21.2505 9.23258 21.2636 9.15279 21.2753 9.07471C21.1433 9.00224 21.0002 8.94029 20.8462 8.8875C20.8081 8.87443 20.7689 8.86184 20.7293 8.84962Z"
        fill="#22284F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6055 9.08865C15.6312 8.91721 15.7375 8.77718 15.8807 8.70655C15.9461 8.67417 16.0182 8.65633 16.0947 8.65633H19.1977C19.5653 8.65633 19.9085 8.68126 20.2217 8.73356C20.3111 8.74834 20.398 8.76545 20.4829 8.78463C20.5673 8.80418 20.6496 8.82606 20.7294 8.85001C20.769 8.86223 20.8083 8.87493 20.8467 8.88752C21.0008 8.94031 21.1439 9.00275 21.276 9.07472C21.4313 8.05085 21.2747 7.35373 20.7391 6.72248C20.1486 6.02743 19.0829 5.73001 17.7192 5.73001H13.7601C13.4816 5.73001 13.2439 5.93933 13.2009 6.22429L11.5519 17.0279C11.5194 17.2416 11.6789 17.4344 11.8874 17.4344H14.3316L15.6055 9.08865Z"
        fill="#28356A"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default PayPal;
