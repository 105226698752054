export type FormDataState = {
  Policy: string;
  X__Amz__Algorithm: string;
  X__Amz__Credential: string;
  X__Amz__Date: string;
  X__Amz__Signature: string;
  X__Amz__Security__Token: string;
  bucket: string;
  key: string;
};

export const getFormData = (uploadDetails: FormDataState, file: File) => {
  const formData = new FormData();

  formData.append("Policy", uploadDetails?.Policy || "");
  formData.append("X-Amz-Algorithm", uploadDetails?.X__Amz__Algorithm || "");
  formData.append("X-Amz-Credential", uploadDetails?.X__Amz__Credential || "");
  formData.append("X-Amz-Date", uploadDetails?.X__Amz__Date || "");
  formData.append("X-Amz-Signature", uploadDetails?.X__Amz__Signature || "");
  formData.append(
    "X-Amz-Security-Token",
    uploadDetails?.X__Amz__Security__Token || ""
  );
  formData.append("bucket", uploadDetails?.bucket || "");
  formData.append("key", uploadDetails?.key || "");
  formData.append("Content-Type", file?.type || "");
  formData.append("file", file || "");

  return formData;
};
