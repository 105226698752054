import cx from 'classnames';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import Icons from '../../assets/icons';
import Label from '../../base/Label';

const styles = {
  field:
    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-black focus:border-black',
  button: ' bg-steel-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-steel-600',
  errorMsg: '!text-red-500 text-sm',
  errorField: '!border-red-300 !text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500',
};

type Styling = {
  label?: string;
  field?: string;
  button?: string;
  errorMsg?: string;
  errorField?: string;
};

export type TextFieldProps = {
  id: string;
  label?: string;
  type?: 'text' | 'email' | 'password' | 'textarea' | 'date' | 'time' | 'url' | 'tel' | 'number' | 'radio';
  rows?: number;
  help?: string;
  customStyling?: Styling;
  value?: string | number | object;
  disabled?: boolean;
  placeholder?: string;
  prefix?: string;
  step?: string;
  min?: string;
  max?: string;
  onFocus?: () => void;
  forceHasError?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  ariaLabel?: string;
};

export const TextField = ({
  id,
  label,
  type = 'text',
  rows,
  help,
  customStyling,
  forceHasError = false,
  placeholder,
  ariaLabel,
  ...props
}: TextFieldProps) => {
  const as = type === 'textarea' ? 'textarea' : 'input';
  const { errors, submitCount } = useFormikContext<any>();
  const hasError = (errors[id] !== undefined && submitCount >= 0) || forceHasError;
  let fieldStyle = styles.field;
  if (hasError) fieldStyle += ` ${styles.errorField}`;
  let customFieldStyle = customStyling?.field;
  if (hasError) customFieldStyle += ` ${styles.errorField}`;

  return (
    <>
      {label && (
        <Label customClass={cx(customStyling?.label ? customStyling.label : '')} label={label} htmlFor={id} key={id} />
      )}
      <div className="relative">
        <Field
          {...props}
          id={id}
          name={id}
          type={type}
          as={as}
          rows={rows}
          className={cx(customStyling?.field ? customFieldStyle : `${fieldStyle}`)}
          placeholder={placeholder}
          aria-label={ariaLabel}
        />

        {hasError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <Icons.AlertCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {hasError && <ErrorMessage component="a" className={styles.errorMsg} name={id} />}

      {help && <p className="text-steel-400 text-sm mt-1">{help}</p>}
    </>
  );
};
