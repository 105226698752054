import { PageHeader, SubHeading } from '@portal/ui';
import ProfileForm from 'components/profile/ProfileForm';

const ProfilePage = () => {
  return (
    <>
      <div className="py-8 border-b border-gray-200">
        <PageHeader title="Profile" />
      </div>
      <SubHeading
        title="Profile details"
        description=" Information about you that will be displayed throughout the platform."
      />
      <ProfileForm />
    </>
  );
};

export default ProfilePage;
