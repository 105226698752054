import { Tab } from '@headlessui/react';
import { classNames } from '../../utils/helper';

type Tabs = {
  id: string;
  name: string;
  component: React.ReactNode;
};

export type TabsProps = {
  tabsData: Tabs[];
};

const VerticalTabs = ({ tabsData }: TabsProps) => {
  return (
    <div className="flex mt-6 w-full">
      <Tab.Group>
        <Tab.List className=" flex flex-col items-start justify-start w-52 pr-11">
          {tabsData.map((tabTitle) => (
            <Tab
              key={tabTitle.id}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-md py-2 px-3 text-sm font-medium leading-5 text-gray-500 flex justify-start',
                  selected ? 'bg-gray-100 text-gray-700' : ''
                )
              }
            >
              {tabTitle.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full">
          {tabsData.map((posts, idx: number) => (
            <Tab.Panel key={idx}>{posts.component}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default VerticalTabs;
