import { Link, useNavigate } from 'react-router-dom';
import { NavBar, classNames, isActivePath, Icons } from '@portal/ui';
import logo from 'assets/growthware_logo_white.png';
import { useDispatch, useSelector } from 'redux/hooks';
import { signOut } from 'redux/thunks/user';

const Header = () => {
  const attributes = useSelector((state) => state.user.attributes);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signOutClicked = (e: any) => {
    e.preventDefault();
    dispatch(signOut());
    navigate('/login');
  };

  const settingClicked = (e: any) => {
    e.preventDefault();
    navigate('/profile');
  };

  const navigation = [
    {
      name: 'Home',
      link: <Link to="/">{'Home'}</Link>,
      pathname: '/',
    },
    {
      name: 'Customers',
      link: <Link to="/customers">{'Customers'}</Link>,
      pathname: '/customers',
    },
  ];

  const userNavigation = [
    { name: 'Profile', href: '#', action: settingClicked },
    { name: 'Sign out', href: '#', action: signOutClicked },
  ];

  return (
    <NavBar
      user={{
        name: attributes?.name ?? '',
        email: attributes?.email ?? '',
        imageUrl: attributes?.avatar ?? '',
      }}
      logo={logo}
      navigation={navigation}
      userNavigation={userNavigation}
      extra={
        <Link to="/settings">
          <div
            className={classNames(
              isActivePath('/settings')
                ? 'bg-gray-800 text-white'
                : 'text-gray-100 hover:text-gray-100 hover:bg-gray-700',
              'w-10 h-10 rounded-md flex justify-center items-center  focus:text-white focus:bg-gray-700 '
            )}
          >
            <Icons.Settings className="h-5 w-5" color="white" />
          </div>
        </Link>
      }
    />
  );
};

export { Header };
