import { Dialog, Transition } from '@headlessui/react';
import { Fragment, cloneElement, useMemo } from 'react';
import Icons from '../../assets/icons';

export interface ChildrenProps {
  onClose?: () => void;
}

export type DrawerProps = {
  children?: React.ReactElement<ChildrenProps>;
  open: boolean;
  onClose: () => void;
};

export type DrawerTitleProps = {
  title: string;
  subTitle?: string;
  onClose?: () => void;
  submitBtn?: React.ReactNode;
};

export type DrawerFooterProps = {
  children: React.ReactNode;
};

const DrawerContainer = ({ open, children, onClose }: DrawerProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden bg-slate-900 bg-opacity-70">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10 md:max-w-xl">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    {children && cloneElement(children, { onClose: onClose })}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const Title = ({ onClose, title, subTitle, submitBtn }: DrawerTitleProps) => {
  const cancelButton = useMemo(
    () => (
      <button type="button" className="rounded-md bg-white  text-gray-400 hover:text-gray-500" onClick={onClose}>
        <span className="sr-only">Close panel</span>
        <Icons.Cross className="h-6 w-6" aria-hidden="true" />
      </button>
    ),
    [onClose]
  );

  return (
    <div className="px-4 py-6 md:py-4 sticky md:relative top-0 z-10 border-b border-steel-200 md:border-none bg-white sm:px-6 ">
      <div className="flex items-start justify-between">
        <div className="flex">
          <div className="ml-3 flex md:hidden h-7 items-center ">{cancelButton}</div>
          <div className="flex flex-col px-7 md:px-0">
            <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
            {subTitle && <p className="text-sm text-gray-500">{subTitle}</p>}
          </div>
        </div>
        <div className="ml-3 hidden md:flex h-7 items-center">{cancelButton}</div>
        <div className="ml-3 flex md:hidden h-7 items-center">{submitBtn}</div>
      </div>
    </div>
  );
};

const Footer = ({ children }: DrawerFooterProps) => {
  return (
    <div className="flex-shrink-0 px-4 py-4 hidden md:flex justify-end border-t sticky bottom-0 bg-white border-gray-300">
      {children}
    </div>
  );
};

const Drawer = Object.assign(DrawerContainer, { Title, Footer });
export default Drawer;
