import Authentication from '@portal/auth';
import Apollo from '@portal/auth/Apollo';

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

console.log(`userpool id=${userPoolId}`);
console.log(`client id=${clientId}`);

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
};

export const Auth = new Authentication(poolData);
export const client = new Apollo(poolData).getClient();
