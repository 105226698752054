import { useLocation, Navigate } from 'react-router-dom';

import { Layout } from '../layout';
import { useSelector } from 'redux/hooks';

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const status = useSelector((state) => state.user.session.status);

  if (status === 'signed-in') {
    return <Layout>{children}</Layout>;
  } else if (status === 'loading') {
    return null; // TODO Show a page loader instead
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

export { RequireAuth };
