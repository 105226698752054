import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

export const Close = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = ICON_COLOR }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={color}
        d="M17.29 5.29l-12 12c-.4.39-.4 1.02 0 1.41 .39.39 1.02.39 1.41 0l12-12c.39-.4.39-1.03 0-1.42 -.4-.4-1.03-.4-1.42 0Zm-12 1.41l12 12c.39.39 1.02.39 1.41 0 .39-.4.39-1.03 0-1.42l-12-12c-.4-.4-1.03-.4-1.42 0 -.4.39-.4 1.02 0 1.41Z"
      ></path>
    </svg>
  );
};
