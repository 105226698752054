import { useCallback, useState } from 'react';
import { Avatar, Button, FileUpload, SubHeading, HorizontalLabel, TextField } from '@portal/ui';
import { getFormData, FormDataState } from '@portal/utils/forms';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'redux/hooks';
import { Partner } from 'types/Partner';
import { partnerSchema } from 'components/validation/partnerSchema';
import { useRequestPartnerLogoUpdateMutation, useUpdatePartnerLogoMutation } from 'graphql/mutation.generated';
import { UpdatePartnerInput } from 'graphql/types';
import { getInitials } from '@portal/utils/string';
import { fetchUserAttributes } from 'redux/thunks/user';

export type FormState = {
  name: string;
  logo: string;
};

const General = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const dispatch = useDispatch();
  const partnerDetails = useSelector((state) => state.user.attributes?.partnerAccount);

  const [requestPartnerLogoUpdateMutation] = useRequestPartnerLogoUpdateMutation();

  const [updatePartnerLogoMutation] = useUpdatePartnerLogoMutation({
    onCompleted: () => {
      toast.success('Profile updated');
      dispatch(fetchUserAttributes());
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const requestLogoUpdate = (values: Partner) => {
    requestPartnerLogoUpdateMutation({
      variables: {
        partnerAccountId: partnerDetails?.id ?? '',
        imgMimeType: values?.logo?.type || '',
      },
    }).then((res) => {
      const uploadDetails = res?.data?.requestPartnerLogoUpdate;
      const formData = getFormData(uploadDetails as FormDataState, values.logo as File);

      fetch(uploadDetails?.formPostUrl || '', {
        method: 'POST',
        body: formData,
      }).then(async (res) => {
        updatePartnerLogoMutation({
          variables: {
            data: {
              partnerAccountId: partnerDetails?.id || '',
              acknowledgePartnerLogoUpdate: true,
              partnerName: values.name,
            },
          },
        });
        setIsUpdating(false);
      });
    });
  };

  const updatePartnerProfile = async (values: Partner) => {
    if (values.logo && values.logo.type) {
      setIsUpdating(true);
      requestLogoUpdate(values);
      return;
    }
    const updates: UpdatePartnerInput = {
      partnerAccountId: partnerDetails?.id || '',
    };
    if (values.name) {
      updates.partnerName = values.name;
    }
    if (!values.logo) {
      updates.acknowledgePartnerLogoUpdate = false;
    }
    setIsUpdating(true);
    await updatePartnerLogoMutation({
      variables: { data: updates },
    });
    setIsUpdating(false);
  };

  const getLogoUrl = useCallback((img: File) => {
    return img ? URL.createObjectURL(img) : '';
  }, []);

  const initialValues: FormState = {
    name: partnerDetails?.name || '',
    logo: partnerDetails?.logo || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={partnerSchema}
      onSubmit={(values) => {
        updatePartnerProfile(values as unknown as Partner);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <SubHeading title="Partner profile" description="Update your company logo and details here." />
            <div className="py-6 border-b border-gray-200 flex gap-8">
              <div className="w-3/12">
                <HorizontalLabel
                  labelFor="company-name"
                  label="Company Name"
                  info="This will be displayed throughout the platform"
                />
              </div>
              <div className="w-9/12 pl-8 pr-16">
                <TextField id="company-name" type="text" />
              </div>
            </div>
            <div className="py-6 border-b border-gray-200 flex gap-8">
              <div className="w-3/12">
                <HorizontalLabel label="Company logo" labelFor="company-logo" />
              </div>
              <div className="w-9/12 pl-8 pr-16 flex">
                <div className="flex-shrink-0 mb-1 mr-16">
                  <Avatar
                    id="company-logo"
                    size={'large'}
                    imageUrl={typeof values.logo === 'object' ? getLogoUrl(values.logo) : values.logo}
                    initials={getInitials(values.name)}
                  />
                  {values.logo && (
                    <button className="text-sm text-gray-600 mt-2" onClick={() => setFieldValue('logo', '')}>
                      Remove
                    </button>
                  )}
                </div>
                <div className="w-96">
                  <FileUpload
                    onChange={(logo) => setFieldValue('logo', logo)}
                    uploadInfoText="SVG, PNG, JPG or GIF (max. 800x400px)"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center py-5 gap-4">
              <Button title="save settings" loading={isUpdating} displayType="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default General;
