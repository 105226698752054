import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useRef } from 'react';
import { AriaButtonProps, useButton } from 'react-aria';
import Icons from '../../assets/icons';

export type ButtonProps = AriaButtonProps<'button'> & {
  title?: string;
  displayType?: 'primary' | 'secondary' | 'danger' | 'text';
  prefixIcon?: ReactJSXElement;
  suffixIcon?: ReactJSXElement;
  iconOnly?: boolean;
  loading?: boolean;
  onClick?: (event: any) => void;
  disabled?: boolean;
  id?: string;
  isPressed?: boolean;
  customClass?: string;
};

export const Button = (props: ButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { children, displayType, prefixIcon, suffixIcon, loading, onClick, disabled, title, iconOnly } = props;

  const initialClasses =
    'text-sm rounded-md shadow-sm font-medium items-center inline-flex px-4 py-2 border border-steel-300 cursor-pointer gap-1 outline-none focus:outline-none focus:ring-2 focus:ring-offset-2';
  const secondaryClass = `${initialClasses} !bg-white !hover:bg-steel-50 !text-steel-700 !focus:ring-primary-500`;
  const primaryClass = `${initialClasses} !hover:bg-primary-600 !bg-primary !primary-btn !border-primary-600 !hover:border-primary-700 !focus:ring-primary-500 !text-white`;
  const dangerClass = `${initialClasses} !bg-error-600 !text-white`;
  const iconOnlyClass = `!px-2 !ring-0 !shadow-none !hover:bg-steel-50`;
  const text = `${initialClasses} !bg-transparent !hover:bg-transparent !border-transparent !focus:ring-0 !text-sm !text-steel-600 !p-2 !border-0 !ring-0 !shadow-none`;
  const disabledClass = `${initialClasses} !cursor-not-allowed !border-transparent !focus:outline-none !bg-steel-100 !text-steel-400`;
  const disabledIconOnly = `${initialClasses} !p-1 !border-0 !ring-0 !shadow-none !cursor-not-allowed !focus:outline-none !text-steel-200`;

  let buttonClass = '';

  switch (displayType) {
    case 'primary':
      buttonClass = primaryClass;
      break;
    case 'danger':
      buttonClass = dangerClass;
      break;
    case 'secondary':
      buttonClass = secondaryClass;
      break;
    case 'text':
      buttonClass = text;
      break;
    default:
      buttonClass = initialClasses;
      break;
  }

  if (disabled) {
    buttonClass = disabledClass;
  }

  if (iconOnly) {
    buttonClass += ' ' + iconOnlyClass;
  }

  if (iconOnly && disabled) {
    buttonClass = disabledIconOnly;
  }

  return (
    <button
      {...buttonProps}
      disabled={disabled ?? loading}
      onClick={onClick}
      ref={ref}
      className={buttonClass}
      title={title}
    >
      {prefixIcon && !loading && prefixIcon}

      {loading && <Icons.Spinner />}

      {children}

      {suffixIcon && suffixIcon}
    </button>
  );
};

export default Button;
