import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const Dollar = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = ICON_COLOR, className = '' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="-5 -1 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833328 13.3332C0.833328 15.1741 2.32571 16.6665 4.16666 16.6665H7.49999C9.34094 16.6665 10.8333 15.1741 10.8333 13.3332C10.8333 11.4922 9.34094 9.99984 7.49999 9.99984H4.16666C2.32571 9.99984 0.833328 8.50745 0.833328 6.6665C0.833328 4.82555 2.32571 3.33317 4.16666 3.33317H7.49999C9.34094 3.33317 10.8333 4.82555 10.8333 6.6665M5.83333 1.6665V18.3332"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Dollar;
