import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useMergeState } from '@portal/react-hooks/use-merge-state';
import {
  Avatar,
  Badge,
  BadgeType,
  Button,
  DataTable,
  DeleteModal,
  Drawer,
  EmptyState,
  Icons,
  PageHeader,
  RowActionMenu,
} from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import { RowActionMenuProps } from '@portal/ui/components/display/RowActionMenu';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { capitalizeText, getInitials } from '@portal/utils/string';
import type { PaginationState, SortingState } from '@tanstack/react-table';
import { CustomerForm, CustomerFormProps } from 'components/customers/CustomerForm';
import {
  useCreatePartnerCustomerMutation,
  useDeletePartnerCustomerMutation,
  useUpdatePartnerCustomerMutation,
} from 'graphql/mutation.generated';
import { FindAllPartnerCustomersQuery, useFindAllPartnerCustomersLazyQuery } from 'graphql/query.generated';
import { FindAllPartnerCustomersFilters } from 'graphql/types';
import { useSelector } from 'redux/hooks';

const badgeMappings = {
  ACTIVE: BadgeType.Success,
  INACTIVE: BadgeType.Primary,
  INVITED: BadgeType.Primary,
} as const;

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllPartnerCustomersFilters | undefined;
};

type CustomerRow = FindAllPartnerCustomersQuery['findAllPartnerCustomers']['customers'][number];

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: undefined,
};

const CustomersPage = () => {
  const [customerFormMode, setCustomerFormMode] = useState<CustomerFormProps['mode'] | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerRow | null>(null);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [selectedCustomerToDelete, setSelectedCustomerToDelete] = useState<CustomerRow | null>(null);
  const partnerAccountId = useSelector((state) => state.user.attributes?.partnerAccount.id);

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const closeDeleteCustomerModal = useCallback(() => setSelectedCustomerToDelete(null), []);

  const [findAllPartnerCustomersQuery, { data, loading, refetch }] = useFindAllPartnerCustomersLazyQuery({
    fetchPolicy: 'network-only', // Ignore cache and use server state
  });

  const [createPartnerCustomerMutation, { loading: createPartnerCustomerLoading }] = useCreatePartnerCustomerMutation({
    onCompleted: () => {
      toast.success('Customer invited');
      closeCustomerFormDrawer();
      resetTableState();
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [updatePartnerCustomerMutation, { loading: updatePartnerCustomerLoading }] = useUpdatePartnerCustomerMutation({
    onCompleted: () => {
      toast.success('Customer saved');
      closeCustomerFormDrawer();
      resetTableState();
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [deletePartnerCustomerMutation, { loading: deletePartnerCustomerLoading }] = useDeletePartnerCustomerMutation({
    onCompleted: () => {
      toast.success('Customer deleted');
      closeDeleteCustomerModal();
      resetTableState();
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            nameLike: value,
            primaryPhoneLike: value,
            businessOwnerNameLike: value,
            businessOwnerEmailLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = data?.findAllPartnerCustomers.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [data?.findAllPartnerCustomers.count]);

  const openCustomerFormDrawer = useCallback((mode: typeof customerFormMode) => setCustomerFormMode(mode), []);
  const closeCustomerFormDrawer = useCallback(() => {
    setCustomerFormMode(null);
    setSelectedCustomer(null);
  }, []);

  const onCustomerFormSubmit: CustomerFormProps['onSubmit'] = (data, { setErrors }) => {
    if (customerFormMode === 'create') {
      createPartnerCustomerMutation({
        variables: {
          data: {
            ...data,
            partnerAccountId: partnerAccountId ?? '',
          },
        },
      }).then((res) => {
        const errors = res.errors as ApolloError | undefined;
        if (errors?.message.toLowerCase().includes('email already exists')) {
          setErrors({ businessOwnerEmail: 'Customer already exists' });
        } else if (errors) {
          toast.error('Something went wrong');
        }
      });
    } else {
      updatePartnerCustomerMutation({
        variables: {
          data: {
            name: data.businessName,
            primaryPhone: data.businessPrimaryPhone,
            businessOwnerName: data.businessOwnerName,
            businessOwnerEmail: data.businessOwnerEmail,
            merchantAccountId: selectedCustomer?.id ?? '',
          },
        },
      }).then((res) => {
        const errors = res.errors as ApolloError | undefined;
        if (errors?.message.toLowerCase().includes('email already exists')) {
          setErrors({ businessOwnerEmail: 'Customer already exists' });
        } else if (errors) {
          toast.error('Something went wrong');
        }
      });
    }
  };

  const onCustomerDelete = () => {
    deletePartnerCustomerMutation({
      variables: { merchantAccountId: selectedCustomerToDelete?.id ?? '' },
    }).then((res) => {
      if (res.errors) {
        toast.error('Something went wrong');
      }
    });
  };

  useEffect(() => {
    if (partnerAccountId) {
      findAllPartnerCustomersQuery({
        variables: {
          partnerAccountId,
          pagination: {
            offset: tableState.pageIndex * tableState.pageSize,
            limit: tableState.pageSize,
          },
          filters: tableState.filters,
          orderBy: {
            name: ternary(tableState.sorting.find((col) => col.id === 'name')?.desc, 'desc', 'asc', undefined),
            owner: ternary(tableState.sorting.find((col) => col.id === 'owner')?.desc, 'desc', 'asc', undefined),
            primaryPhone: ternary(
              tableState.sorting.find((col) => col.id === 'primaryPhone')?.desc,
              'desc',
              'asc',
              undefined
            ),
            createdAt: ternary(
              tableState.sorting.find((col) => col.id === 'createdAt')?.desc,
              'desc',
              'asc',
              undefined
            ),
            status: ternary(tableState.sorting.find((col) => col.id === 'status')?.desc, 'desc', 'asc', undefined),
          },
        },
        onError: (error) => {
          console.error(error);
          toast.error('Something went wrong');
        },
      });
    }
  }, [partnerAccountId, tableState.pageIndex, tableState.pageSize, tableState.filters, tableState.sorting]);

  const rowActions: RowActionMenuProps<CustomerRow>['rowActions'] = [
    {
      name: 'Edit',
      action: (data) => {
        openCustomerFormDrawer('edit');
        setSelectedCustomer(data ?? null);
      },
      isDisabled: (data) => data?.status !== 'INVITED',
    },
    {
      name: 'Delete',
      action: (data) => {
        setSelectedCustomerToDelete(data ?? null);
      },
      isDisabled: (data) => data?.status !== 'INVITED',
    },
  ];

  const columns: ColumnType<CustomerRow>[] = [
    {
      id: 'name',
      name: 'Name',
      cell: (info) => {
        const customer = info.row.original;
        return (
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              <Avatar imageUrl={customer.logo ?? ''} initials={getInitials(customer.name)} />
            </div>
            <div className="font-medium text-gray-900 ml-4">{customer.name}</div>
          </div>
        );
      },
    },
    {
      id: 'owner',
      name: 'Business owner',
      cell: (info) => {
        const customer = info.row.original;
        return (
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              <Avatar
                imageUrl={customer.businessOwner?.avatar ?? ''}
                initials={getInitials(customer.businessOwner?.name ?? '')}
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">{customer.businessOwner?.name}</div>
              <div className="text-gray-500">{customer.businessOwner?.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'primaryPhone',
      name: 'Primary phone',
      cell: (info) => info.row.original.primaryPhone,
    },
    {
      id: 'createdAt',
      name: 'Created',
      cell: (info) => moment(info.row.original.createdAt).format('MMM D, YYYY'),
    },
    {
      id: 'growthIndexScore',
      name: 'Growth Index',
      // cell: (info) => `Level ${info}`,
    },
    {
      id: 'status',
      name: 'Status',
      cell: (info) => (
        <Badge text={capitalizeText(info.row.original.status)} type={badgeMappings[info.row.original.status]} />
      ),
    },
    {
      id: 'actions',
      name: '',
      cell: ({ row: { original } }) => <RowActionMenu row={original} rowActions={rowActions} />,
    },
  ];

  return (
    <>
      <PageHeader
        title="Customers"
        actions={[
          <Button title="bulk import" prefixIcon={<Icons.Import />} displayType="secondary">
            Bulk import
          </Button>,
          <Button
            title="add customer"
            prefixIcon={<Icons.Plus aria-hidden="true" color="white" />}
            onPress={() => openCustomerFormDrawer('create')}
            displayType="primary"
          >
            Add customer
          </Button>,
        ]}
      />

      <div className="py-6">
        <DataTable
          columns={columns}
          data={data?.findAllPartnerCustomers.customers ?? []}
          noDataElement={
            <EmptyState
              title={'No customers yet'}
              subTitle={'Get started by adding a new customer.'}
              btnText={'Add customer'}
              icon={<Icons.BuildingStore />}
              onClick={() => openCustomerFormDrawer('create')}
            />
          }
          tableState={tableState}
          updateTableState={updateTableState}
          loading={loading}
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
          recordFriendlyName="customer"
        />
      </div>

      <Drawer open={customerFormMode != null} onClose={closeCustomerFormDrawer}>
        <>
          <Drawer.Title
            title={customerFormMode === 'create' ? 'Add a customer' : 'Edit customer'}
            onClose={closeCustomerFormDrawer}
          />
          <CustomerForm
            mode={customerFormMode ?? 'create'}
            onSubmit={onCustomerFormSubmit}
            initialValues={
              selectedCustomer
                ? {
                    businessName: selectedCustomer.name,
                    businessPrimaryPhone: selectedCustomer.primaryPhone,
                    businessOwnerName: selectedCustomer.businessOwner?.name,
                    businessOwnerEmail: selectedCustomer.businessOwner?.email,
                  }
                : undefined
            }
            loading={customerFormMode === 'create' ? createPartnerCustomerLoading : updatePartnerCustomerLoading}
          />
        </>
      </Drawer>

      <DeleteModal
        onClose={closeDeleteCustomerModal}
        title={'Delete customer'}
        open={selectedCustomerToDelete != null}
        onConfirm={onCustomerDelete}
        loading={deletePartnerCustomerLoading}
        content={'Are you sure you want to delete this merchant? This action cannot be undone.'}
      />
    </>
  );
};

export default CustomersPage;
