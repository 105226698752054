import Icons from '../../assets/icons';
import { Toaster, ToastBar, toast } from 'react-hot-toast';

const Toast = () => {
  return (
    <Toaster
      toastOptions={{
        success: {
          icon: <Icons.CheckCircle className="w-5 h-5 text-green-600" />,
        },
        error: {
          icon: <Icons.CrossCircle className="w-5 h-5 text-red-600" />,
        },
      }}
      position="top-right"
      reverseOrder={false}
      gutter={8}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button onClick={() => toast.dismiss(t.id)}>
                  <Icons.Cross className="w-4 h-4 text-gray-500" />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
