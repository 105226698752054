import React from 'react';
import { useBreadcrumbs, useBreadcrumbItem, AriaBreadcrumbItemProps } from 'react-aria';

export interface BreadcrumbItemProps extends AriaBreadcrumbItemProps {
  isCurrent?: boolean;
  href?: string;
}

const BreadcrumbItem = (props: BreadcrumbItemProps) => {
  const ref = React.useRef(null);
  const { itemProps } = useBreadcrumbItem(
    {
      ...props,
      elementType: props.isCurrent ? 'h3' : 'a',
    },
    ref
  );
  let breadcrumbContent;
  if (props.isCurrent) {
    breadcrumbContent = (
      <h3
        {...itemProps}
        ref={ref}
        style={{
          margin: 0,
          fontSize: '1em',
          color: '#4A5578',
          textTransform: 'capitalize',
          fontWeight: props.isCurrent ? 'bold' : 'regular',
        }}
      >
        {props.children}
      </h3>
    );
  } else {
    breadcrumbContent = (
      <>
        <a
          {...itemProps}
          ref={ref}
          href={props.href}
          style={{
            color: props.isDisabled ? 'var(--gray)' : '#4A5578',
            textDecoration: props.isCurrent || props.isDisabled ? 'none' : 'underline',
            fontWeight: props.isCurrent ? 'bold' : 'regular',
            cursor: props.isCurrent || props.isDisabled ? 'default' : 'pointer',
          }}
        >
          {props.children}
        </a>
        <span aria-hidden="true" style={{ padding: '0 1.4rem', color: '#4A5578' }}>
          {'›'}
        </span>
      </>
    );
  }

  return <li className="flex">{breadcrumbContent}</li>;
};

const Breadcrumbs = (props: any) => {
  const { navProps } = useBreadcrumbs(props);
  const childCount = React.Children.count(props.children);

  return (
    <nav {...navProps}>
      <ol style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
        {React.Children.map(props.children, (child, i) =>
          React.cloneElement(child, { isCurrent: i === childCount - 1 })
        )}
      </ol>
    </nav>
  );
};

export default Object.assign(Breadcrumbs, {
  Item: BreadcrumbItem,
});
