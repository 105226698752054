import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Icons from '../../assets/icons';
import Button from '../Button';

export type ModalProps = {
  children?: React.ReactNode;
  open: boolean;
  icon?: React.ReactNode;
  iconClass?: string;
  title: string;
  subTitle?: string | React.ReactNode;
  onClose: () => void;
  closeIcon?: boolean;
  actions?: React.ReactNode[];
  size?: 'sm' | 'md' | 'lg' | 'xl' | string;
  loading?: boolean;
};

const Modal = ({
  open,
  children,
  icon,
  onClose,
  closeIcon,
  title,
  subTitle,
  loading = false,
  actions = [],
  iconClass = 'inline-flex justify-center rounded-full w-10 h-10 text-white p-2 bg-red-100 mr-4',
  size = 'md',
}: ModalProps) => {
  const sizes = ['sm', 'md', 'lg', 'xl', 'screen-sm'].includes(size);
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50 modal" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-900 bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center bg-slate-900 bg-opacity-70">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${!sizes && 'w-full'} max-w-${
                    sizes ? size : 'md'
                  } transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  {loading ? (
                    <div className="flex justify-center">
                      <Icons.Spinner />
                    </div>
                  ) : (
                    <>
                      <div className="flex items-start justify-between">
                        <div className="flex">
                          {icon && <div className={iconClass}>{icon}</div>}
                          <div className="flex-col">
                            <Dialog.Title as="h3" className="header text-lg font-semibold leading-6 text-steel-900">
                              {title}
                            </Dialog.Title>
                            {subTitle && (
                              <div className="mt-2">
                                <p className="description text-sm text-steel-500">{subTitle}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {closeIcon && (
                          <Button title="close window" displayType="text" iconOnly onClick={onClose}>
                            <Icons.Cross className="focus:outline-none active:outline-none outline-none" />
                          </Button>
                        )}
                      </div>
                      {children}
                      <div className="flex justify-end items-center">
                        {actions.map((item, idx) => (
                          <div key={idx} className="flex-none mx-2 mt-5">
                            {item}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;
