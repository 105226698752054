import React from 'react';

function GooglePay({ width = 34, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0578 15.528V12.5933H17.5725C18.1931 12.5933 18.717 12.3854 19.144 11.9752L19.2465 11.8712C20.0266 11.022 19.9754 9.69911 19.144 8.91346C18.7284 8.49752 18.159 8.27223 17.5725 8.28378H15.1411V15.528H16.0578ZM16.0579 11.7036V9.17336H17.5956C17.9259 9.17336 18.2391 9.30045 18.4726 9.53153C18.9681 10.0168 18.9795 10.8255 18.5011 11.3281C18.2676 11.5765 17.9373 11.7152 17.5956 11.7036H16.0579ZM23.5228 10.9584C23.1299 10.5945 22.5947 10.4096 21.9171 10.4096C21.0459 10.4096 20.3911 10.7331 19.9583 11.3744L20.7669 11.8885C21.063 11.4495 21.4673 11.23 21.9797 11.23C22.3043 11.23 22.6175 11.3513 22.8623 11.5708C23.1015 11.7788 23.2381 12.0792 23.2381 12.3969V12.6106C22.8851 12.4142 22.4409 12.3102 21.8943 12.3102C21.2566 12.3102 20.7441 12.4604 20.3626 12.7666C19.9811 13.0728 19.7875 13.4772 19.7875 13.9913C19.7761 14.4592 19.9754 14.9041 20.3284 15.2044C20.6872 15.528 21.1427 15.6897 21.6779 15.6897C22.31 15.6897 22.8111 15.4066 23.1926 14.8405H23.2324V15.528H24.1093V12.472C24.1093 11.8308 23.9157 11.3224 23.5228 10.9584ZM21.0347 14.6152C20.8467 14.4766 20.7328 14.2513 20.7328 14.0087C20.7328 13.7371 20.8581 13.5118 21.103 13.3328C21.3536 13.1537 21.6668 13.0612 22.037 13.0612C22.5495 13.0555 22.9482 13.171 23.2329 13.4021C23.2329 13.7949 23.0792 14.1357 22.7773 14.4246C22.504 14.7019 22.1338 14.8579 21.7465 14.8579C21.4903 14.8636 21.2397 14.777 21.0347 14.6152ZM26.0795 17.7058L29.1429 10.5714H28.1464L26.7286 14.1299H26.7115L25.2595 10.5714H24.2631L26.2731 15.2102L25.1343 17.7058H26.0795Z"
        fill="#3C4043"
      />
      <path
        d="M12.8929 11.9579C12.8929 11.6748 12.8701 11.3918 12.8246 11.1145H8.95829V12.7147H11.1733C11.0822 13.2288 10.7861 13.691 10.3533 13.9798V15.0196H11.6744C12.4488 14.2975 12.8929 13.2288 12.8929 11.9579Z"
        fill="#4285F4"
      />
      <path
        d="M8.95847 16.0248C10.0631 16.0248 10.9969 15.6551 11.6745 15.0197L10.3535 13.9798C9.9834 14.234 9.51079 14.3784 8.95847 14.3784C7.88798 14.3784 6.98262 13.6448 6.65806 12.6627H5.29717V13.7372C5.99185 15.141 7.40967 16.0248 8.95847 16.0248Z"
        fill="#34A853"
      />
      <path
        d="M6.6582 12.6627C6.48735 12.1485 6.48735 11.5882 6.6582 11.0683V9.99954H5.2971C4.71051 11.1722 4.71051 12.5587 5.2971 13.7314L6.6582 12.6627Z"
        fill="#FBBC04"
      />
      <path
        d="M8.95847 9.35253C9.54496 9.34098 10.1087 9.56628 10.53 9.97643L11.703 8.7864C10.9571 8.08162 9.97771 7.69457 8.95847 7.70612C7.40967 7.70612 5.99185 8.59576 5.29717 9.99954L6.65806 11.074C6.98262 10.0862 7.88798 9.35253 8.95847 9.35253Z"
        fill="#EA4335"
      />
      <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" stroke="#F2F4F7" />
    </svg>
  );
}

export default GooglePay;
