import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const Target = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = ICON_COLOR }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6653 8.83333V5.33333L21.1653 1.83333L22.332 4.16667L24.6653 5.33333L21.1653 8.83333H17.6653ZM17.6653 8.83333L12.9987 13.4999M24.6654 13.5C24.6654 19.9433 19.442 25.1667 12.9987 25.1667C6.55538 25.1667 1.33203 19.9433 1.33203 13.5C1.33203 7.05668 6.55538 1.83333 12.9987 1.83333M18.832 13.5C18.832 16.7217 16.2204 19.3333 12.9987 19.3333C9.77704 19.3333 7.16536 16.7217 7.16536 13.5C7.16536 10.2783 9.77704 7.66667 12.9987 7.66667"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Target;
