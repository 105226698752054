import { useRef, useEffect, RefObject } from 'react';

export type CheckBoxProps = {
  id: string;
  label?: string;
  checked: boolean;
  indeterminate?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const Checkbox = ({ id, label, checked, indeterminate, ...rest }: CheckBoxProps) => {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <div className={`flex items-center gap-2 ${!label && 'w-0'} px-3`}>
      <input
        id={id}
        ref={ref as RefObject<HTMLInputElement>}
        type="checkbox"
        className="text-slate-900 border-border-color rounded-md cursor-pointer focus:ring-gray-900"
        checked={checked}
        {...rest}
      />
      {label && (
        <label className="text-sm font-medium" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
