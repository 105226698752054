import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const Growthwear = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = ICON_COLOR, className = '' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5556 9.33333V11M16.5556 11V20C16.5556 20.5523 16.1078 21 15.5556 21H11H6.44444C5.89216 21 5.44444 20.5523 5.44444 20V9.33333V9.557C5.44444 10.2552 4.74707 10.7385 4.09332 10.4933L2.17996 9.77582C1.57688 9.54966 1.34176 8.8207 1.69903 8.28479L6.06588 1.73452C6.34562 1.3149 6.89406 1.16925 7.34514 1.39479L10.5528 2.99862C10.8343 3.13938 11.1657 3.13938 11.4472 2.99862L14.6549 1.39479C15.1059 1.16925 15.6544 1.3149 15.9341 1.73452L20.301 8.28479C20.6582 8.8207 20.4231 9.54966 19.82 9.77582L16.5556 11Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Growthwear;
