import { Toast } from '@portal/ui';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <>
      <Toast />
      <AppRoutes />
    </>
  );
}

export default App;
