import { ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

export const Globe = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = '#404968', className = '' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1665 9.99984H17.8332M1.1665 9.99984C1.1665 14.6022 4.89746 18.3332 9.49984 18.3332M1.1665 9.99984C1.1665 5.39746 4.89746 1.6665 9.49984 1.6665M17.8332 9.99984C17.8332 14.6022 14.1022 18.3332 9.49984 18.3332M17.8332 9.99984C17.8332 5.39746 14.1022 1.6665 9.49984 1.6665M9.49984 1.6665C11.5842 3.94846 12.7688 6.90987 12.8332 9.99984C12.7688 13.0898 11.5842 16.0512 9.49984 18.3332M9.49984 1.6665C7.41544 3.94846 6.23088 6.90987 6.1665 9.99984C6.23088 13.0898 7.41544 16.0512 9.49984 18.3332"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Globe;
