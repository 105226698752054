import {
  Avatar,
  FileUpload,
  TimeZone,
  Button,
  Icons,
  HorizontalLabel,
  TextField,
  FieldFeedback,
  checkFieldErrors,
} from '@portal/ui';
import { getInitials } from '@portal/utils/string';
import { FormState } from 'components/profile/ProfileForm';
import { Form, useFormikContext } from 'formik';
import { formatOffset, getLogoUrl } from '@portal/utils/misc';
import { components } from 'react-select';
import moment from 'moment';

type ProfileFieldProps = {
  loading: boolean;
};

const ProfileFields = ({ loading }: ProfileFieldProps) => {
  const { setFieldValue, values, touched, errors } = useFormikContext<FormState>();
  return (
    <Form>
      <div className="py-6 border-b border-gray-200 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel label="Name" labelFor="name" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <TextField id="name" type="text" />
        </div>
      </div>
      <div className="py-6 border-b border-gray-200 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel label="Your photo" labelFor="photo" info="This will be displayed on your profile" />
        </div>
        <div className="w-9/12 pl-8 pr-16 flex">
          <div className="flex-shrink-0 mb-1 mr-16">
            <Avatar
              id="photo"
              size="large"
              imageUrl={typeof values.avatar === 'object' ? getLogoUrl(values.avatar) : values.avatar}
              initials={getInitials(values.name)}
            />
            {values.avatar && (
              <Button
                title="remove profile photo"
                displayType="text"
                type="button"
                onClick={() => setFieldValue('avatar', '')}
              >
                Remove
              </Button>
            )}
          </div>
          <div className="flex">
            <div className="w-96">
              <FileUpload onChange={(value) => setFieldValue('avatar', value)} />
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 border-b border-gray-200 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel labelFor="tzName" label="Time zone" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <TimeZone
            id="tzName"
            selectedComponent={({ children, ...props }) => {
              const timezone = props.getValue();
              let value: number | null = null;
              if (timezone && timezone.length) {
                value = moment.tz(timezone[0].value).utcOffset();
              }

              return (
                <components.Control {...props} className="flex  gap-2 !justify-start">
                  <Icons.Clock />
                  <div className="flex">{children}</div>
                  <p className="text-sm text-gray-500">{value ? `UTC ${formatOffset(value)}` : ''}</p>
                </components.Control>
              );
            }}
            timezone={values.tzName}
            onChange={(value) => setFieldValue('tzName', value)}
          />
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'tzName'
            )}
            errorMessage={errors?.tzName}
            transitionClasses="mt-1"
          />
        </div>
      </div>
      <div className="flex justify-end items-center py-5 gap-4">
        <Button title="save profile" loading={loading} displayType="primary" type="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default ProfileFields;
