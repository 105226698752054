import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const Homebase = ({ width = ICON_WIDTH, height = ICON_HEIGHT, color = ICON_COLOR, className = '' }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 11C4 7.13401 7.13401 4 11 4M15.4999 6.5L10.9999 11M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Homebase;
