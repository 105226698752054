import { BadgeType } from '@portal/ui';
import { colorMappings } from '../../../../../apps/merchant-portal/src/utils/status';

export type BadgeProps = {
  type: BadgeType;
  text?: string;
  children?: React.ReactNode;
};

const Badge = ({ text, type = BadgeType.Success }: BadgeProps) => {
  const generatedClass = colorMappings[type];
  return (
    <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${generatedClass}`}>
      {text}
    </span>
  );
};

export default Badge;
