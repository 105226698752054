import { Routes, Route } from 'react-router-dom';

import { RequireAuth } from 'components/routing';

import LoginPage from 'views/Login/LoginPage';
import CustomersPage from 'views/Customers/CustomersPage';
import SettingsPage from 'views/Settings/SettingsPage';
import ProfilePage from 'views/Profile/ProfilePage';
import ResetPasswordPage from 'views/ResetPassword/ResetPasswordPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <RequireAuth>
            <CustomersPage />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/passwords/new" element={<ResetPasswordPage />} />
      <Route
        path="/customers"
        element={
          <RequireAuth>
            <CustomersPage />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        }
      />
      <Route
        path="/settings"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
