/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPartnerLogoUpdateMutationVariables = Types.Exact<{
  partnerAccountId: Types.Scalars['String'];
  imgMimeType: Types.Scalars['String'];
}>;


export type RequestPartnerLogoUpdateMutation = { __typename?: 'Mutation', requestPartnerLogoUpdate: { __typename?: 'UploadForm', formPostUrl: string, key?: string | null, bucket?: string | null, X__Amz__Algorithm?: string | null, X__Amz__Credential?: string | null, X__Amz__Date?: string | null, Policy?: string | null, X__Amz__Signature?: string | null, X__Amz__Security__Token?: string | null } };

export type UpdatePartnerLogoMutationVariables = Types.Exact<{
  data: Types.UpdatePartnerInput;
}>;


export type UpdatePartnerLogoMutation = { __typename?: 'Mutation', updatePartner: { __typename?: 'PartnerAccount', id: string, name: string, logo?: string | null, createdAt: any, updatedAt: any, address?: { __typename?: 'Address', id: string, addressLine1: string, addressLine2: string, city: string, state: string, pincode: string, countryCode: string, createdAt: any, updatedAt: any } | null } };

export type CreatePartnerCustomerMutationVariables = Types.Exact<{
  data: Types.CreatePartnerCustomerInput;
}>;


export type CreatePartnerCustomerMutation = { __typename?: 'Mutation', createPartnerCustomer: { __typename?: 'MerchantAccount', id: string } };

export type UpdatePartnerCustomerMutationVariables = Types.Exact<{
  data: Types.UpdatePartnerCustomerInput;
}>;


export type UpdatePartnerCustomerMutation = { __typename?: 'Mutation', updatePartnerCustomer: { __typename?: 'MerchantAccount', id: string } };

export type DeletePartnerCustomerMutationVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
}>;


export type DeletePartnerCustomerMutation = { __typename?: 'Mutation', deletePartnerCustomer?: boolean | null };

export type UpdatePartnerUserMutationVariables = Types.Exact<{
  data: Types.UpdatePartnerUserInput;
}>;


export type UpdatePartnerUserMutation = { __typename?: 'Mutation', updatePartnerUser: { __typename?: 'PartnerAccountUser', id: string, name: string, email: string, cognitoUsername: string, tzName: string, avatar?: string | null, createdAt: any, updatedAt: any } };

export type RequestPartnerUserAvatarUpdateMutationVariables = Types.Exact<{
  imgMimeType: Types.Scalars['String'];
}>;


export type RequestPartnerUserAvatarUpdateMutation = { __typename?: 'Mutation', requestPartnerUserAvatarUpdate: { __typename?: 'UploadForm', formPostUrl: string, key?: string | null, bucket?: string | null, X__Amz__Algorithm?: string | null, X__Amz__Credential?: string | null, X__Amz__Date?: string | null, Policy?: string | null, X__Amz__Signature?: string | null, X__Amz__Security__Token?: string | null } };


export const RequestPartnerLogoUpdateDocument = gql`
    mutation RequestPartnerLogoUpdate($partnerAccountId: String!, $imgMimeType: String!) {
  requestPartnerLogoUpdate(
    partnerAccountId: $partnerAccountId
    imgMimeType: $imgMimeType
  ) {
    formPostUrl
    key
    bucket
    X__Amz__Algorithm
    X__Amz__Credential
    X__Amz__Date
    Policy
    X__Amz__Signature
    X__Amz__Security__Token
  }
}
    `;
export type RequestPartnerLogoUpdateMutationFn = Apollo.MutationFunction<RequestPartnerLogoUpdateMutation, RequestPartnerLogoUpdateMutationVariables>;

/**
 * __useRequestPartnerLogoUpdateMutation__
 *
 * To run a mutation, you first call `useRequestPartnerLogoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPartnerLogoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPartnerLogoUpdateMutation, { data, loading, error }] = useRequestPartnerLogoUpdateMutation({
 *   variables: {
 *      partnerAccountId: // value for 'partnerAccountId'
 *      imgMimeType: // value for 'imgMimeType'
 *   },
 * });
 */
export function useRequestPartnerLogoUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RequestPartnerLogoUpdateMutation, RequestPartnerLogoUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPartnerLogoUpdateMutation, RequestPartnerLogoUpdateMutationVariables>(RequestPartnerLogoUpdateDocument, options);
      }
export type RequestPartnerLogoUpdateMutationHookResult = ReturnType<typeof useRequestPartnerLogoUpdateMutation>;
export type RequestPartnerLogoUpdateMutationResult = Apollo.MutationResult<RequestPartnerLogoUpdateMutation>;
export type RequestPartnerLogoUpdateMutationOptions = Apollo.BaseMutationOptions<RequestPartnerLogoUpdateMutation, RequestPartnerLogoUpdateMutationVariables>;
export const UpdatePartnerLogoDocument = gql`
    mutation updatePartnerLogo($data: UpdatePartnerInput!) {
  updatePartner(data: $data) {
    id
    name
    logo
    createdAt
    updatedAt
    address {
      id
      addressLine1
      addressLine2
      city
      state
      pincode
      countryCode
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdatePartnerLogoMutationFn = Apollo.MutationFunction<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>;

/**
 * __useUpdatePartnerLogoMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerLogoMutation, { data, loading, error }] = useUpdatePartnerLogoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartnerLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>(UpdatePartnerLogoDocument, options);
      }
export type UpdatePartnerLogoMutationHookResult = ReturnType<typeof useUpdatePartnerLogoMutation>;
export type UpdatePartnerLogoMutationResult = Apollo.MutationResult<UpdatePartnerLogoMutation>;
export type UpdatePartnerLogoMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerLogoMutation, UpdatePartnerLogoMutationVariables>;
export const CreatePartnerCustomerDocument = gql`
    mutation CreatePartnerCustomer($data: CreatePartnerCustomerInput!) {
  createPartnerCustomer(data: $data) {
    id
  }
}
    `;
export type CreatePartnerCustomerMutationFn = Apollo.MutationFunction<CreatePartnerCustomerMutation, CreatePartnerCustomerMutationVariables>;

/**
 * __useCreatePartnerCustomerMutation__
 *
 * To run a mutation, you first call `useCreatePartnerCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerCustomerMutation, { data, loading, error }] = useCreatePartnerCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePartnerCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerCustomerMutation, CreatePartnerCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartnerCustomerMutation, CreatePartnerCustomerMutationVariables>(CreatePartnerCustomerDocument, options);
      }
export type CreatePartnerCustomerMutationHookResult = ReturnType<typeof useCreatePartnerCustomerMutation>;
export type CreatePartnerCustomerMutationResult = Apollo.MutationResult<CreatePartnerCustomerMutation>;
export type CreatePartnerCustomerMutationOptions = Apollo.BaseMutationOptions<CreatePartnerCustomerMutation, CreatePartnerCustomerMutationVariables>;
export const UpdatePartnerCustomerDocument = gql`
    mutation UpdatePartnerCustomer($data: UpdatePartnerCustomerInput!) {
  updatePartnerCustomer(data: $data) {
    id
  }
}
    `;
export type UpdatePartnerCustomerMutationFn = Apollo.MutationFunction<UpdatePartnerCustomerMutation, UpdatePartnerCustomerMutationVariables>;

/**
 * __useUpdatePartnerCustomerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerCustomerMutation, { data, loading, error }] = useUpdatePartnerCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartnerCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerCustomerMutation, UpdatePartnerCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerCustomerMutation, UpdatePartnerCustomerMutationVariables>(UpdatePartnerCustomerDocument, options);
      }
export type UpdatePartnerCustomerMutationHookResult = ReturnType<typeof useUpdatePartnerCustomerMutation>;
export type UpdatePartnerCustomerMutationResult = Apollo.MutationResult<UpdatePartnerCustomerMutation>;
export type UpdatePartnerCustomerMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerCustomerMutation, UpdatePartnerCustomerMutationVariables>;
export const DeletePartnerCustomerDocument = gql`
    mutation DeletePartnerCustomer($merchantAccountId: String!) {
  deletePartnerCustomer(merchantAccountId: $merchantAccountId)
}
    `;
export type DeletePartnerCustomerMutationFn = Apollo.MutationFunction<DeletePartnerCustomerMutation, DeletePartnerCustomerMutationVariables>;

/**
 * __useDeletePartnerCustomerMutation__
 *
 * To run a mutation, you first call `useDeletePartnerCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerCustomerMutation, { data, loading, error }] = useDeletePartnerCustomerMutation({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *   },
 * });
 */
export function useDeletePartnerCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartnerCustomerMutation, DeletePartnerCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePartnerCustomerMutation, DeletePartnerCustomerMutationVariables>(DeletePartnerCustomerDocument, options);
      }
export type DeletePartnerCustomerMutationHookResult = ReturnType<typeof useDeletePartnerCustomerMutation>;
export type DeletePartnerCustomerMutationResult = Apollo.MutationResult<DeletePartnerCustomerMutation>;
export type DeletePartnerCustomerMutationOptions = Apollo.BaseMutationOptions<DeletePartnerCustomerMutation, DeletePartnerCustomerMutationVariables>;
export const UpdatePartnerUserDocument = gql`
    mutation UpdatePartnerUser($data: UpdatePartnerUserInput!) {
  updatePartnerUser(data: $data) {
    id
    name
    email
    cognitoUsername
    tzName
    avatar
    createdAt
    updatedAt
  }
}
    `;
export type UpdatePartnerUserMutationFn = Apollo.MutationFunction<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>;

/**
 * __useUpdatePartnerUserMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerUserMutation, { data, loading, error }] = useUpdatePartnerUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePartnerUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>(UpdatePartnerUserDocument, options);
      }
export type UpdatePartnerUserMutationHookResult = ReturnType<typeof useUpdatePartnerUserMutation>;
export type UpdatePartnerUserMutationResult = Apollo.MutationResult<UpdatePartnerUserMutation>;
export type UpdatePartnerUserMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>;
export const RequestPartnerUserAvatarUpdateDocument = gql`
    mutation RequestPartnerUserAvatarUpdate($imgMimeType: String!) {
  requestPartnerUserAvatarUpdate(imgMimeType: $imgMimeType) {
    formPostUrl
    key
    bucket
    X__Amz__Algorithm
    X__Amz__Credential
    X__Amz__Date
    Policy
    X__Amz__Signature
    X__Amz__Security__Token
  }
}
    `;
export type RequestPartnerUserAvatarUpdateMutationFn = Apollo.MutationFunction<RequestPartnerUserAvatarUpdateMutation, RequestPartnerUserAvatarUpdateMutationVariables>;

/**
 * __useRequestPartnerUserAvatarUpdateMutation__
 *
 * To run a mutation, you first call `useRequestPartnerUserAvatarUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPartnerUserAvatarUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPartnerUserAvatarUpdateMutation, { data, loading, error }] = useRequestPartnerUserAvatarUpdateMutation({
 *   variables: {
 *      imgMimeType: // value for 'imgMimeType'
 *   },
 * });
 */
export function useRequestPartnerUserAvatarUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RequestPartnerUserAvatarUpdateMutation, RequestPartnerUserAvatarUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPartnerUserAvatarUpdateMutation, RequestPartnerUserAvatarUpdateMutationVariables>(RequestPartnerUserAvatarUpdateDocument, options);
      }
export type RequestPartnerUserAvatarUpdateMutationHookResult = ReturnType<typeof useRequestPartnerUserAvatarUpdateMutation>;
export type RequestPartnerUserAvatarUpdateMutationResult = Apollo.MutationResult<RequestPartnerUserAvatarUpdateMutation>;
export type RequestPartnerUserAvatarUpdateMutationOptions = Apollo.BaseMutationOptions<RequestPartnerUserAvatarUpdateMutation, RequestPartnerUserAvatarUpdateMutationVariables>;