import Icons from '../../assets/icons';
import Button from '../../base/Button';
import Modal from '../../base/Modal';

interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  content: string;
  title: string;
  loading?: boolean;
}

const DeleteModal = ({ open, onClose, onConfirm, content, title, loading }: DeleteModalProps) => {
  return (
    <Modal
      open={open}
      title={title}
      loading={loading}
      icon={<Icons.Trash />}
      iconClass={'inline-flex justify-center rounded-full w-9 h-9 text-error-600 p-2 bg-red-100 mr-4'}
      onClose={onClose}
      actions={[
        <Button title="cancel" displayType="secondary" onClick={onClose}>
          Cancel
        </Button>,
        <Button title="delete" displayType="danger" onClick={onConfirm} loading={loading}>
          Delete
        </Button>,
      ]}
      subTitle={content}
    />
  );
};

export default DeleteModal;
