import classNames from 'classnames';
import { timezones } from './timezones';
import Select, { ControlProps, components } from 'react-select';

export type TimeZoneProps = {
  id: string;
  timezone: string;
  onChange: (value: string) => void;
  customStyling?: { [key: string]: ({ isSelected }: { isSelected: boolean }) => string };
  selectedComponent?: React.FC<ControlProps<{ value: string; label: string }>>;
  showDropdownIndicator?: boolean;
};

const TimeZone = ({
  id,
  onChange,
  timezone,
  selectedComponent,
  customStyling,
  showDropdownIndicator,
}: TimeZoneProps) => {
  return (
    <Select
      inputId={id}
      components={{
        ...(selectedComponent && { Control: selectedComponent }),
        DropdownIndicator: showDropdownIndicator
          ? (props) => (
              <components.DropdownIndicator {...props} className="absolute right-0"></components.DropdownIndicator>
            )
          : null,
      }}
      classNames={
        customStyling ?? {
          valueContainer: () => 'text-sm p-1',
          input: () => '[&>input]:text-red !text-red',
          placeholder: () => 'text-black text-sm ',
          menuList: () => 'bg-white border m-1 rounded-md ',
          noOptionsMessage: () => 'text-black text-sm p-2',
          indicatorSeparator: () => 'hidden',

          control: () =>
            'border  bg-white border-gray-300 focus:border-gray-300 hover:border-gray-300 shadow-sm !text-base sm:text-sm p-2 rounded-md',
          option: ({ isSelected }) =>
            classNames(
              'active:bg-gray-50 hover:text-black hover:bg-gray-50 !text-sm sm:text-sm  cursor-default rounded-md select-none px-3 py-2 active:text-black text-black !cursor-pointer',
              isSelected ? 'bg-gray-50' : 'bg-white'
            ),
        }
      }
      unstyled
      options={timezones}
      onChange={(e: any) => onChange(e.value)}
      value={timezones.filter(function (option) {
        return option.value === timezone;
      })}
      menuPlacement={'auto'}
      openMenuOnClick
    />
  );
};

export default TimeZone;
