import { AriaButtonProps } from 'react-aria';

type ButtonProps = AriaButtonProps<'button'> & {
  text: string;
  onClick: (event: unknown) => void;
  disabled?: boolean;
  id: string;
  active?: boolean;
};

export type ButtonGroupProps = {
  buttons: ButtonProps[];
};

const disabledClass = 'cursor-not-allowed text-steel-400';

const activeClass = '!bg-white !shadow-sm !text-black';

const StartButton = ({ onClick, disabled, id, text, active }: ButtonProps) => (
  <button
    type="button"
    className={`flex text-steel-600 items-center rounded-md py-[0.4375rem] pl-3 pr-3 text-sm font-semibold hover:text-steel-900 ${
      disabled ? disabledClass : ''
    } ${active ? activeClass : ''}`}
    onClick={onClick}
    disabled={disabled}
    id={id}
    role="tab"
    aria-selected={active}
  >
    {text}
  </button>
);

const EndButton = ({ onClick, disabled, id, text, active }: ButtonProps) => (
  <button
    type="button"
    className={`flex text-steel-600 items-center rounded-md py-[0.4375rem] pl-3 pr-3 text-sm font-semibold hover:text-steel-900  ${
      disabled ? disabledClass : ''
    } ${active ? activeClass : ''}`}
    onClick={onClick}
    disabled={disabled}
    id={id}
    role="tab"
    aria-selected={active}
  >
    {text}
  </button>
);

const MiddleButton = ({ onClick, disabled, id, text, active }: ButtonProps) => (
  <button
    type="button"
    className={`flex text-steel-600 items-center rounded-md py-[0.4375rem] pl-3 pr-3 text-sm font-semibold hover:text-steel-900  ${
      disabled ? disabledClass : ''
    } ${active ? activeClass : ''}`}
    onClick={onClick}
    disabled={disabled}
    id={id}
    role="tab"
    aria-selected={active}
  >
    {text}
  </button>
);

export const ButtonGroup = ({ buttons }: ButtonGroupProps) => {
  return (
    <div
      className="flex max-w-fit shrink whitespace-nowrap space-x-1 rounded-lg bg-steel-100 p-1 gap-1 group-hover:bg-steel-200 transition-colors"
      role="tablist"
      aria-orientation="horizontal"
    >
      {buttons.map((button, index) => {
        if (index === 0) {
          return <StartButton {...button} key={button.id} />;
        } else if (index === buttons.length - 1) {
          return <EndButton {...button} key={button.id} />;
        } else return <MiddleButton {...button} key={button.id} />;
      })}
    </div>
  );
};
