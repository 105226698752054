import AddContact from './AddContact';
import Alert from './Alert';
import { AlertCircle } from './AlertCircle';
import AlignLeft from './AlignLeft';
import { AnnotationQuestion } from './AnnotationQuestion';
import { ArrowDown } from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { ArrowUp } from './ArrowUp';
import Bell from './Bell';
import BuildingStore from './BuildingStore';
import Calendar from './Calendar';
import CardTypes from './CardTypes';
import { Check } from './Check';
import { CheckCircle } from './CheckCircle';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronSelector from './ChevronSelector';
import { CircleFilled } from './CircleFilled';
import Clock from './Clock';
import Contact from './Contact';
import CreditCard from './CreditCard';
import Cross from './Cross';
import { CrossCircle } from './CrossCircle';
import Cube from './Cube';
import Desktop from './Desktop';
import Dollar from './Dollar';
import { DotsHorizontal } from './DotsHorizontal';
import { DotsVertical } from './DotsVertical';
import DoubleEllipsisVertical from './DoubleEllipsisVertical';
import { DownloadCloud } from './DownloadCloud';
import Edit from './Edit';
import Email from './Email';
import Featured from './Featured';
import { File } from './File';
import FileUpload from './FileUpload';
import Filters from './Filters';
import { Globe } from './Globe';
import Growthwear from './Growthwear';
import Growthwearbooks from './GrowthwearBooks';
import { HelpCircle } from './HelpCircle';
import Homebase from './Homebase';
import Homeline from './Homeline';
import Import from './Import';
import Inbox from './Inbox';
import { InfoCircle } from './InfoCircle';
import { Key } from './Key';
import Listing from './Listing';
import { Location } from './Location';
import Logout from './Logout';
import Menu from './Menu';
import Message from './Message';
import { Minus } from './Minus';
import NarrowLeftArrow from './NarrowLeftArrow';
import Pencil from './Pencil';
import { Plus } from './Plus';
import Power from './Power';
import Reporting from './Reporting';
import Rocket from './Rocket';
import Search from './Search';
import Settings from './Settings';
import Spinner from './Spinner';
import Support from './Support';
import Tag from './Tag';
import Target from './Target';
import { Trash } from './Trash';
import Users from './Users';
import { Error } from './Error';
import { Close } from './Close';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Contact,
  Desktop,
  Growthwearbooks,
  Growthwear,
  Homebase,
  Reporting,
  Support,
  Dollar,
  BuildingStore,
  AddContact,
  Import,
  Homeline,
  Logout,
  Cube,
  Users,
  Check,
  Error,
  DoubleEllipsisVertical,
  Inbox,
  Calendar,
  CreditCard,
  Listing,
  Settings,
  Search,
  Bell,
  ChevronRight,
  ChevronLeft,
  FileUpload,
  Power,
  ChevronDown,
  Alert,
  Edit,
  Rocket,
  Pencil,
  Featured,
  Target,
  Email,
  Message,
  ArrowLeft,
  Spinner,
  NarrowLeftArrow,
  ChevronSelector,
  Menu,
  Tag,
  Cross,
  Filters,
  Clock,
  Trash,
  Plus,
  CircleFilled,
  Minus,
  Key,
  AnnotationQuestion,
  CheckCircle,
  DownloadCloud,
  CrossCircle,
  InfoCircle,
  AlertCircle,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  HelpCircle,
  File,
  CardTypes,
  Globe,
  Location,
  DotsVertical,
  DotsHorizontal,
  AlignLeft,
  Close,
};
