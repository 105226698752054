import { useMemo, useState, useCallback, useEffect } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
  getPaginationRowModel,
  getFilteredRowModel,
  FilterFn,
  CellContext,
  TableOptions,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import Icons from '../../assets/icons';
import { plural } from 'pluralize';
import { DropdownItem } from '../Dropdown';
import Checkbox from '../Checkbox';
import EmptyState from '../EmptyState';
import Pagination from './pagination';
import Spinner from '../Spinner';
import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';

export type ColumnType<P = any, Q = unknown> = {
  name: string;
  cell?: (info: CellContext<P, Q>) => React.ReactNode;
  id: string;
  visible?: boolean;
  display?: 'mobile' | 'desktop' | 'all';
};

export type CustomClasses = {
  borderClass?: string;
  thClass?: string;
  searchClass?: string;
  tableClass?: string;
};

export type CustomSearch = {
  placeholder?: string;
  width?: string;
};

export type TableActions = {
  icon?: React.ReactNode;
  name: string;
  action: () => void;
};

// export type Filter<T> = Record<string, never>;

export type TableProps<T> = {
  columns: ColumnType[];
  data: T[];
  pagination?: boolean;
  filters?: DropdownItem[];
  showSearch?: boolean;
  rowSelection?: boolean;
  noDataElement?: React.ReactNode;
  tableActions?: TableActions[];
  customClasses?: CustomClasses;
  recordFriendlyName?: string;
  customSearch?: CustomSearch;
  tableOptions?: Partial<TableOptions<T>>;
  loading?: boolean;
  loadingRowsCount?: number;
  onGlobalFilterChange?: (value: string) => void;
  showPaginationNumbers?: boolean;
  hideColumnHeadersOnMobile?: boolean;
};

const getColumns = <T,>(columns: ColumnType<T>[], rowSelection: boolean) => {
  const columnHelper = createColumnHelper<T>();

  const tableColumns = columns.map((column) => {
    return columnHelper.accessor(
      // @ts-expect-error it's assignable, all good!
      column.id,
      {
        header: column.name,
        ...(column.visible && { visible: column.visible }),
        ...(column?.cell && { cell: (info) => column?.cell && column.cell(info) }),
        display: column.display || 'all',
      }
    );
  });

  if (rowSelection) {
    tableColumns.unshift({
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          {...{
            id: 'select-all',
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          {...{
            id: `select-${row.id}`,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    });
  }

  return tableColumns;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const Table = <T,>({
  columns,
  data,
  pagination,
  rowSelection = false,
  noDataElement,
  recordFriendlyName = 'record',
  customClasses,
  tableOptions,
  loading = false,
  showPaginationNumbers,
  hideColumnHeadersOnMobile = false,
}: TableProps<T>) => {
  const [selectedRows, onRowSelect] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const isMobileView = useIsMobileView();

  const tableColumns = useMemo(() => getColumns<T>(columns, rowSelection), [columns]);
  const columnVisibility = useMemo(() => {
    const visibleColumns: Record<string, boolean> = {};
    columns.forEach((column) => {
      visibleColumns[column.id] = column.visible
        ? column.visible
        : isMobileView && column.display === 'desktop'
        ? false
        : true;
    });

    return visibleColumns;
  }, [columns, isMobileView]);
  const renderNoDataElement = useCallback(() => {
    if (noDataElement) {
      return noDataElement;
    }

    return <EmptyState title="No data" />;
  }, [noDataElement]);

  const table = useReactTable({
    data,
    columns: tableColumns,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
    state: {
      rowSelection: selectedRows,
      globalFilter: globalFilter,
      columnVisibility,
    },
    enableRowSelection: rowSelection,
    enableGlobalFilter: true,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onRowSelectionChange: onRowSelect,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    ...tableOptions, // Override
  });

  useEffect(() => {
    table.setColumnVisibility(columnVisibility);
  }, [columnVisibility]);
  const borderClass = customClasses?.borderClass ?? 'min-w-full divide-gray-300 table-border';
  const tableClass = customClasses?.tableClass ?? 'min-w-full table';
  const thClass = customClasses?.thClass ?? `bg-gray-50 divide-gray-200 table-th`;

  return (
    <>
      {data.length > 0 || loading || globalFilter.length > 0 ? (
        <div className={borderClass}>
          <table className={tableClass}>
            <thead className={`${hideColumnHeadersOnMobile && 'hidden'} md:table-header-group `}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={thClass + (isMobileView && ' [&>*:first-child]:pl-[4rem]')}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className={`table-th  ${isMobileView ? 'px-8' : 'px-3'}`}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none flex items-center pr-3'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <Icons.ArrowUp className="ml-2 h-3 w-3" />,
                            desc: <Icons.ArrowDown className="ml-2 h-3 w-3" />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                      {/* Add a sort direction indicator */}
                      {/* {column.isSorted
                  ? column.isSortedDesc
                  ? <ArrowDownIcon className="ml-2 h-3 w-3" />
                  : <ArrowUpIcon className="ml-2 h-3 w-3" />
                  : ''} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y bg-transparent table-body">
              {loading ? (
                <tr className="relative h-[20rem] min-h-full">
                  <Spinner />
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr
                    className={`hover:bg-steel-50 bg-white ${
                      isMobileView && !hideColumnHeadersOnMobile && '[&>*:first-child]:pl-[4rem]'
                    }`}
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={`whitespace-nowrap sm:px-6 md:px-3 py-4 text-sm text-gray-500 ${
                          isMobileView ? 'px-5' : 'px-4'
                        } table-td`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {!loading && globalFilter.length > 0 && table.getPrePaginationRowModel().rows.length === 0 && (
            <EmptyState
              title="No results found"
              subTitle={`Your search didn't match any ${plural(recordFriendlyName)}. Try searching for something else.`}
            />
          )}

          {pagination && (
            <div className="border-t border-table-border">
              <Pagination table={table} showPaginationNumbers={showPaginationNumbers} />
            </div>
          )}
        </div>
      ) : (
        renderNoDataElement()
      )}
    </>
  );
};

export default Table;
