import { ICON_COLOR, ICON_HEIGHT, ICON_WIDTH } from '../../data/icons';
import { IconProps } from '../../types/IconType';

const DoubleEllipsisVertical = ({
  color = ICON_COLOR,
  width = ICON_WIDTH,
  height = ICON_HEIGHT,
  className = '',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 -4 23 23"
      strokeWidth="1.5"
      stroke={color}
      height={height}
      width={width}
      className={`flex gap-2 ${className}`}
    >
      <path
        d="M8.00016 2.99967C8.4604 2.99967 8.8335 2.62658 8.8335 2.16634C8.8335 1.7061 8.4604 1.33301 8.00016 1.33301C7.53993 1.33301 7.16683 1.7061 7.16683 2.16634C7.16683 2.62658 7.53993 2.99967 8.00016 2.99967Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 8.83301C8.4604 8.83301 8.8335 8.45991 8.8335 7.99967C8.8335 7.53944 8.4604 7.16634 8.00016 7.16634C7.53993 7.16634 7.16683 7.53944 7.16683 7.99967C7.16683 8.45991 7.53993 8.83301 8.00016 8.83301Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 14.6663C8.4604 14.6663 8.8335 14.2932 8.8335 13.833C8.8335 13.3728 8.4604 12.9997 8.00016 12.9997C7.53993 12.9997 7.16683 13.3728 7.16683 13.833C7.16683 14.2932 7.53993 14.6663 8.00016 14.6663Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16683 2.99967C2.62707 2.99967 3.00016 2.62658 3.00016 2.16634C3.00016 1.7061 2.62707 1.33301 2.16683 1.33301C1.70659 1.33301 1.3335 1.7061 1.3335 2.16634C1.3335 2.62658 1.70659 2.99967 2.16683 2.99967Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16683 8.83301C2.62707 8.83301 3.00016 8.45991 3.00016 7.99967C3.00016 7.53944 2.62707 7.16634 2.16683 7.16634C1.70659 7.16634 1.3335 7.53944 1.3335 7.99967C1.3335 8.45991 1.70659 8.83301 2.16683 8.83301Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16683 14.6663C2.62707 14.6663 3.00016 14.2932 3.00016 13.833C3.00016 13.3728 2.62707 12.9997 2.16683 12.9997C1.70659 12.9997 1.3335 13.3728 1.3335 13.833C1.3335 14.2932 1.70659 14.6663 2.16683 14.6663Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleEllipsisVertical;
