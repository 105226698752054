import { Button, Icons } from '@portal/ui';
import { useNavigate } from 'react-router-dom';

const PasswordSetPage = () => {
  const navigate = useNavigate();

  const handleReset = () => {
    navigate('/login');
  };
  return (
    <div className="flex min-h-screen flex-col bg-primary-background justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
        <div className="inline-flex items-center justify-center rounded-full w-10 h-10 text-white p-2 bg-green-100">
          <Icons.CheckCircle className="h-12 w-auto text-green-600" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">Set your password</h2>
        <p className="mt-2 text-center text-sm text-gray-400">
          Welcome to Growthware. Create a password to finish setting up your account.
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <button
          className="text-sm rounded-md shadow-sm font-medium items-center inline-flex px-4 py-2 border cursor-pointer gap-1 !hover:bg-primary-600 !bg-primary !primary-btn !border-primary-600 !hover:border-primary-700 !focus:ring-primary-500 !text-white login-page-btn"
          type="submit"
          onClick={handleReset}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default PasswordSetPage;
