import Affirm from './Affirm';
import Alipay from './Alipay';
import Amazon from './Amazon';
import AMEX from './AMEX';
import ApplePay from './ApplePay';
import Bitpay from './Bitpay';
import Citadele from './Citadele';
import DinersClub from './DinersClub';
import Discover from './Discover';
import Elo from './Elo';
import Etherium from './Etherium';
import Forbrugsforeningen from './Forbrugsforeningen';
import GooglePay from './GooglePay';
import Interac from './Interac';
import JCB from './JCB';
import Klarna from './Klarna';
import Lightcoin from './Lightcoin';
import Maestro from './Maestro';
import Mastercard from './Mastercard';
import Payoneer from './Payoneer';
import PayPal from './PayPal';
import Paysafe from './Paysafe';
import Qiwi from './Qiwi';
import SEPA from './SEPA';
import ShopPay from './ShopPay';
import Skrill from './Skrill';
import Sofort from './Sofort';
import Stripe from './Stripe';
import UnionPay from './UnionPay';
import Verifone from './Verifone';
import Visa from './Visa';
import Webmoney from './Webmoney';
import Default from './Default';

export default {
  Affirm,
  Alipay,
  Amazon,
  AMEX,
  ApplePay,
  Bitpay,
  Citadele,
  DinersClub,
  Discover,
  Elo,
  Etherium,
  Forbrugsforeningen,
  GooglePay,
  Interac,
  JCB,
  Klarna,
  Lightcoin,
  Maestro,
  Mastercard,
  Payoneer,
  PayPal,
  Paysafe,
  Qiwi,
  SEPA,
  ShopPay,
  Skrill,
  Sofort,
  Stripe,
  UnionPay,
  Verifone,
  Visa,
  Webmoney,
  Default,
};
