import { configureStore } from '@reduxjs/toolkit';
import { Keys, localStorage } from 'libs/local-storage';
import { userReducer } from './reducers/user';
import { signInWithTokens } from './thunks/user';

const store = configureStore({
  reducer: {
    user: userReducer,
  },
});

const preloadStore = () => {
  const accessToken = localStorage.get(Keys.accessToken);
  const refreshToken = localStorage.get(Keys.refreshToken);
  if (accessToken && refreshToken) {
    store.dispatch(signInWithTokens(accessToken, refreshToken));
  }
};

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store, preloadStore };
export type { RootState, AppDispatch };
